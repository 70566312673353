<div class="search-bar-component" [style.height]="searchbarHeight()">
  <mat-form-field class="search-bar-form-field no-ripple" [ngClass]="layout.cssClasses()">
    <div class="search-box">
      <input
        matInput
        type="text"
        #searchInput
        class="mat-h3 search-box-input"
        appearance="fill"
        placeholder="Search"
        id="search-box"
        (keydown)="onKeydown($event)"
        (keyup)="onKeyup($event)"
        [value]="displayedSearchString()"
        (input)="onInputChange($event)"
      />

      @if (displayedSearchString().length > 0) {
        <div aria-label="search-clear" class="search-clear clickable" (click)="onClear()">
          <fa-icon class="mat-h3 info-icon" [icon]="faXmark"></fa-icon>
        </div>
      }
      @if (layout.isGT_xsmall()) {
        <fa-icon class="mat-h3 question" [matTooltip]="tooltipText" [icon]="faCircleQuestion"></fa-icon>
      }

      <!-- buttons -->
    </div>
  </mat-form-field>
</div>
