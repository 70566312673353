<div class="ft-dialog-component" [ngClass]="layout.cssClasses()">
  <div mat-dialog-title>
    <!-- card header  -->
    <div class="title">
      <fa-icon [icon]="faUserPen"></fa-icon>
      <h4 cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{ headerTitle() }}</h4>
    </div>

    <div class="title-options">
      <ft-close-button (closeClicked)="close()"></ft-close-button>
    </div>
  </div>

  <mat-dialog-content>
    <form [formGroup]="form" class="ft-dialog-form" (ngSubmit)="close()">
      <div class="hide-scrollbars">
        <formly-form
          [model]="model()"
          [fields]="fields"
          [options]="options"
          [form]="form"
          (modelChange)="onModelChange($event)"
        ></formly-form>
      </div>
    </form>
  </mat-dialog-content>

  <!-- card actions  -->
  <mat-dialog-actions class="ft-dialog-actions">
    <button
      type="submit"
      mat-button
      class="submit-button"
      (click)="onSubmit()"
      [disabled]="!form.valid || !form.dirty"
      aria-label="Submit"
    >
      {{ submitTitle() }}
    </button>
  </mat-dialog-actions>
</div>
