import { FT_propsRecord } from '@ft/lib/crud-helper-lib';

import { gql } from 'apollo-angular';
import {
	UserNotification,
	UserNotificationCreateRecord,
	UserNotificationMutateRecord,
} from './user-notification.model';

export const userNotificationQueryFields = [
	'username',
	'notificationName',
	'schedule',
	'notificationFilters { searchString, fieldFilters, searchFieldFilters }',
	'createdAt',
	'updatedAt',
	'deleted',
	'retrievedAt',
];

export const userNotificationMutateFields: (keyof UserNotification)[] = [
	'username',
	'notificationName',
	'schedule',
	'notificationFilters',
];

export const ApiUrlsUserNotification = {
	getNotifications: (fieldList = userNotificationQueryFields) => gql`
      query($filter: FilterFindManyNotificationInput, $sort: SortFindManyNotificationInput, $skip: Int, $limit: Int) {
        userNotificationMany(filter: $filter, sort: $sort, skip: $skip, limit: $limit) {
          _id
          ${fieldList.join(`\r\n`)}
        }
      }
    `,

	notificationUpdateOne: (_id: string, record: UserNotificationMutateRecord) => {
		const mutateRecord = FT_propsRecord(record);

		return {
			mutation: gql`
          mutation ($filter: FilterUpdateOneNotificationInput!,  $record: UpdateOneNotificationInput!) {
            mutateResult: userNotificationUpdateOne(filter: $filter, record: $record) {
              recordId
              record {
                _id
                ${userNotificationQueryFields.join(`\r\n`)}
              }
            }
          }
        `,
			variables: { _id: _id, record: mutateRecord, filter: { _id: _id, username: '' } },
		};
	},

	notificationCreateOne: (record: UserNotificationCreateRecord) => {
		const mutateRecord = FT_propsRecord(record);

		return {
			mutation: gql`
          mutation ($record: CreateOneNotificationInput!) {
            createResult: userNotificationCreateOne(record: $record) {
              recordId
              record {
                _id
                ${userNotificationQueryFields.join(`\r\n`)}
              }
            }
          }
        `,
			variables: { record: mutateRecord, filter: {} },
		};
	},

	notificationDeleteById: (_id: string) => {
		return {
			mutation: gql`
        mutation ($input: DocumentIdInputInput!) {
          deleteResult: userNotificationDeleteById(input: $input)
        }
      `,
			variables: { input: { documentId: `${_id}` } },
		};
	},

	notificationDeleteOneById: (_id: string) => {
		return {
			mutation: gql`
        mutation ($input: DocumentIdInputInput!, $filter: FilterRemoveOneInput!) {
          deleteResult: userNotificationDeleteOne(input: $input, filter: $filter)
        }
      `,
			variables: { input: { documentId: `${_id}` }, filter: { _id: `${_id}` } },
		};
	},
};
