import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	type OnInit,
	computed,
	effect,
	inject,
	input,
	viewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { NgClass } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppFilterSelectFieldComponent } from '@ft/lib/filter-lib';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BehaviorSubject, Subject } from 'rxjs';

import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { type NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';

import { ActiveFilterService } from '@ft/lib/active-filter-lib';
import { LayoutInfoService } from '@ft/lib/screen-lib';
import { FT_FilteredValues, FilterValue, GetFieldFilter } from '@furnas-technology/common-library/filters';
import { ArtefactService } from '../../data/artefact.service';
import { ArtefactStore } from '../../store/artefact.store';

const artefactTypeFN = 'artefactType';
const artefactNameFN = 'artefactName';

type SearchEvent = {
	term: string;
	items: string[];
};

const FILTER_DEBOUNCE_TIME = 300;
@Component({
	selector: 'ft-artefact-filter-form',
	templateUrl: './artefact-filter-form.component.html',
	styleUrls: ['./artefact-filter-form.component.scss'],
	imports: [
		AppFilterSelectFieldComponent,
		FontAwesomeModule,
		FormsModule,
		MatButtonModule,
		MatCheckboxModule,
		MatDividerModule,
		MatFormFieldModule,
		NgClass,
		NgSelectModule,
		ReactiveFormsModule,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArtefactFilterFormComponent implements OnInit {
	protected destroyRef = inject(DestroyRef);
	protected layout = inject(LayoutInfoService);
	fb = inject(FormBuilder);
	artefactStore = inject(ArtefactStore);
	artefactService = inject(ArtefactService);
	afs = inject(ActiveFilterService);

	// input params
	appFilters = input<boolean>(false);

	rowsLoading = computed(() => this.artefactService.isLoading());

	fieldFilters = computed(() => {
		return this.afs.selectActiveFieldFilters();
	});

	artefactTypeElem = viewChild<NgSelectComponent>('artefactTypeElem');
	artefactNameElem = viewChild<NgSelectComponent>('artefactNameElem');

	artefactTypeFN = artefactTypeFN;
	artefactNameFN = artefactNameFN;

	submitTitle = 'Apply';

	artefactTypeInput$ = new Subject<string>();
	artefactTypeInput = toSignal(this.artefactTypeInput$);
	artefactTypeOptions$ = new BehaviorSubject<string[]>([]);
	selectedArtefactTypes: FilterValue[] = [];

	artefactNameInput$ = new Subject<string>();
	artefactNameInput = toSignal(this.artefactNameInput$);
	artefactNameOptions$ = new BehaviorSubject<string[]>([]);
	selectedArtefactNames: FilterValue[] = [];

	form: FormGroup;

	constructor() {
		console.debug(`${this.constructor.name} - constructor`);

		this.form = this.fb.group({
			competitionDateRanges: this.fb.array([]),
		});

		effect(() => {
			const filteredValues = FT_FilteredValues(this.artefactTypeInput(), this.artefactService.artefactTypes() ?? []);
			this.artefactTypeOptions$.next(filteredValues.map((x) => String(x)));
		});

		effect(() => {
			const filteredValues = FT_FilteredValues(this.artefactNameInput(), this.artefactService.artefactNames() ?? []);
			this.artefactNameOptions$.next(filteredValues.map((x) => String(x)));
		});
	}

	ngOnInit() {
		console.debug(`${this.constructor.name} - ngOnInit`);
		console.debug(`${this.constructor.name} - ngOnInit - appFilters=`, this.appFilters());
		console.debug(`${this.constructor.name} - ngOnInit - fieldFilters=`, this.fieldFilters());

		// load initial filter values
		this.selectedArtefactTypes = GetFieldFilter(artefactTypeFN, this.fieldFilters()).filterValues;
		this.selectedArtefactNames = GetFieldFilter(artefactNameFN, this.fieldFilters()).filterValues;
	}

	onSubmit() {
		// this.closeClicked.emit(true);
	}
}
