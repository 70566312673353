import { CdkVirtualScrollViewport, FixedSizeVirtualScrollStrategy, ScrollingModule } from '@angular/cdk/scrolling';
import { NgClass } from '@angular/common';
import {
	type AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	type OnChanges,
	type OnInit,
	QueryList,
	type SimpleChanges,
	computed,
	effect,
	inject,
	input,
	output,
	signal,
	viewChild,
	viewChildren,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatCardModule } from '@angular/material/card';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GlobalStore } from '@ft/lib/global-lib';
import { LayoutInfoService } from '@ft/lib/screen-lib';
import { SpinnerComponent } from '@furnas-technology/angular-library/components';
import { CustomVirtualScrollDirective } from '@furnas-technology/angular-library/directives';
import { BehaviorSubject, Subject, debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { Artefact, ArtefactType } from '../../data/artefact.model';
import { ArtefactService } from '../../data/artefact.service';
import { ArtefactPanelCardComponent } from '../artefact-panel-card/artefact-panel-card.component';

const DEFAULT_ITEM_SIZE = 250;

export class CustomVirtualScrollStrategy extends FixedSizeVirtualScrollStrategy {
	constructor() {
		super(DEFAULT_ITEM_SIZE, DEFAULT_ITEM_SIZE * 10, DEFAULT_ITEM_SIZE * 20);
		console.debug(`${this.constructor.name}  - FamilytreePanelComponent - CustomVirtualScrollStrategy=`, this);
	}
}

type PanelCardInfo = {
	index: number;
	outlineKey: string;
	cardHeight: number;
	offset: number;
};

type PanelCardObject = {
	[key: string]: PanelCardInfo;
};

type ItemSizeById = { _id: string; size: number };
type TotalItemSizes = { displayedHeight: number; displayedCount: number; itemSizesById: ItemSizeById[] };

@Component({
	selector: 'ft-artefact-panel',
	templateUrl: './artefact-panel.component.html',
	styleUrls: ['./artefact-panel.component.scss'],
	imports: [
		NgClass,
		ArtefactPanelCardComponent,
		CdkVirtualScrollViewport,
		CustomVirtualScrollDirective,
		FontAwesomeModule,
		MatCardModule,
		ScrollingModule,
		SpinnerComponent,
	],
	// providers: [{ provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScrollStrategy }],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArtefactPanelComponent implements OnInit, AfterViewInit, OnChanges {
	protected destroyRef = inject(DestroyRef);
	protected layout = inject(LayoutInfoService);
	private cdr = inject(ChangeDetectorRef);
	artefactService = inject(ArtefactService);
	gss = inject(GlobalStore);

	artefacts = input.required<Artefact[]>();
	artefactTypes = input<ArtefactType[]>([]);
	mostRecent = input<number>(0);
	noArtefactMessage = input<string>('');
	selectedArtefact = output<Artefact>();

	scrollViewport = viewChild('scrollViewport', { read: CdkVirtualScrollViewport });
	artefactCardElements = viewChildren(ArtefactPanelCardComponent);

	rowsLoading = computed(() => this.artefactService.isLoading());

	totalSize = 0;

	loaded = signal(false);

	firstScroll = true;

	itemsRendered = new Subject<Artefact[]>();
	itemsScrolled = new Subject<unknown>();

	trackByFn = (_index: number, artefact: Artefact): string => artefact._id;

	scrolledIndex = 0;

	scrollTop$ = new BehaviorSubject<number>(0);

	cardList?: QueryList<ArtefactPanelCardComponent>;

	totalItemSizes: TotalItemSizes = { displayedHeight: 0, displayedCount: 0, itemSizesById: [] };

	// itemSizesById = computed<{ _id: string; size: number }[]>([]);
	itemSizesById = computed<ItemSizeById[]>(() => {
		const sizes: { _id: string; size: number }[] = [];
		for (const artefact of this.artefacts()) {
			const size =
				this.totalItemSizes.itemSizesById.find((item) => item._id === artefact._id)?.size ?? DEFAULT_ITEM_SIZE;
			sizes.push({ _id: artefact._id, size: size + 10 });
		}
		return sizes;
	});

	totalHeight = computed<number>(() => {
		return this.totalItemSizes.displayedHeight;
	});

	itemSizes = computed<number[]>(() => {
		return this.itemSizesById().map((x) => x.size);
	});

	avgItemSize = computed<number>(() => {
		const avgSize = this.totalItemSizes.displayedCount
			? Math.round(this.totalItemSizes.displayedHeight / this.totalItemSizes.displayedCount)
			: DEFAULT_ITEM_SIZE;
		console.debug(`${this.constructor.name} - avgItemSize=${avgSize}`);
		return avgSize;
	});

	constructor() {
		console.debug(`${this.constructor.name} - constructor`);

		// update when layout changes
		effect(() => {
			if (this.layout.screenWidthHeight() && this.artefactCardElements()) {
				this.updateItemSizes();
			}
		});
	}

	async ngOnInit() {
		// nothing to see here

		this.scrollTop$
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				debounceTime(500),
				distinctUntilChanged(),
				tap((_scrollTop: number) => {
					if (this.scrollViewport() && this.cardList) {
						const curShown = this.cardList.get(this.scrolledIndex);
					}
				}),
			)
			.subscribe();
	}

	ngOnChanges(changes: SimpleChanges) {} // end ngOnChanges

	ngAfterViewInit() {} // end ngAfterViewInit

	onDimensions(panelCardInfo: PanelCardInfo) {}

	filteredArtefactChanged(artefact: Artefact[]) {
		if (this.scrollViewport) {
			this.scrollViewport()?.scrollToIndex(0);
		}
	}

	onScrolledIndexChange(scrolledIndex: number) {
		this.scrolledIndex = scrolledIndex;
		// console.debug(`${this.constructor.name} - onScroll -  onScrolledIndexChange - scrolledIndex=${scrolledIndex}`);
	}

	onScrolled(event: unknown) {
		// nothing to see here
	}

	onScroll(event: Event) {
		const target = event.target as Element;
		// console.debug(`${this.constructor.name} - onScroll - target.ariaLabel=${target.ariaLabel}`);
		if (!target) return;

		const scrollTop = target.scrollTop ?? 0;
		this.scrollTop$.next(scrollTop);
	}

	updateItemSizes(): void {
		let displayedHeight = 0;
		let displayedCount = 0;

		if (!this.artefactCardElements()) {
			this.totalItemSizes.displayedHeight = 0;
			this.totalItemSizes.displayedCount = 0;
			return;
		}

		for (const component of this.artefactCardElements()) {
			const height = component.artefactCard()?.nativeElement.clientHeight ?? 0;
			// console.debug(`${this.constructor.name} - updateItemSizes - id=${component.artefact()?._id}, height=${height}`);

			displayedHeight += height;
			displayedCount += 1;
			const id = component.artefact()?._id;
			// get the item sizes
			if (id) {
				const card = this.totalItemSizes.itemSizesById.find((item) => item._id === id);
				if (card) {
					card.size = height;
				} else {
					this.totalItemSizes.itemSizesById.push({ _id: id, size: height });
				}
			}
		}

		this.totalItemSizes.displayedHeight = displayedHeight;
		this.totalItemSizes.displayedCount = displayedCount;
	}

	onSelected(artefact: Artefact): void {
		if (artefact) {
			this.selectedArtefact.emit(artefact);
		}
	}
}
