import { DestroyRef, Injectable, inject } from '@angular/core';
import { tap } from 'rxjs';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { FT_FilterComponent } from '@ft/lib/active-filter-lib';
import { LayoutInfoService } from '@ft/lib/screen-lib';
import { NotificationDialogData, UserNotification, UserNotificationDialog } from '@ft/lib/user-notifications-lib';
import { FieldFilter, SearchBarField } from '@furnas-technology/common-library/filters';
import { FT_FilterDialog, FT_FilterDialogData } from '../componenets/filter-dialog/filter-dialog.component';

@Injectable({
	providedIn: 'root',
})
export class FilterDialogueService {
	private destroyRef = inject(DestroyRef);
	private dialog = inject(MatDialog);
	private layout = inject(LayoutInfoService);

	constructor() {
		console.debug(`${this.constructor.name} - constructor`);
	}

	/**
	 * Remove active focus from the document
	 */
	private removeActiveFocus() {
		try {
			if (document.activeElement instanceof HTMLElement) {
				document.activeElement.blur();
			}
		} catch (err: unknown) {
			console.error(`❌ ${this.constructor.name} - removeActiveFocus - error=`, err);
		}
	}

	openFilterDialog<T>(
		filterComponent: FT_FilterComponent,
		fieldFilters?: FieldFilter<unknown>[],
		searchBarFields?: SearchBarField<unknown>[],
	): void {
		console.debug(`${this.constructor.name} - openFilterDialog - fieldFilters=`, fieldFilters);

		// Close all open filter dialogs
		this.closeOpenDialogs();

		const panelClass = `ft-filter-panel-${this.layout.windowSize().toLowerCase()}`;

		// filter dialog component must include an input for appFilters
		const data: FT_FilterDialogData<unknown, unknown> = {
			title: `Filters`,
			message: `Enter values to filter data`,
			filterComponent: filterComponent,
			filterComponentInputs: { appFilters: true },
			searchBarFields: searchBarFields ?? [],
		};

		if (filterComponent) {
			this.removeActiveFocus();
			const dialogRef = this.dialog.open(FT_FilterDialog, { panelClass: panelClass, data });

			dialogRef
				.afterClosed()
				.pipe(
					takeUntilDestroyed(this.destroyRef),
					tap((result: { [key: string]: unknown } | undefined) => {
						console.debug(`${this.constructor.name} - openFilterDialog - afterClosed - result=`, result);
						if (result?.['next'] === 'createNotification') {
							this.openNotificationDialog(filterComponent);
						}
					}),
				)
				.subscribe();
		}
	}

	private closeOpenDialogs() {
		const openDialogs = this.dialog.openDialogs;
		for (const openDialog of openDialogs) {
			console.debug(`${this.constructor.name} - openDialog=${openDialog.id}, openDialog=`, openDialog);
			// console.debug(`${this.constructor.name} - closing open dialog=${openDialog.id}`);
			// TODO ... filter to only select FilterDialog components
			// openDialog.close();
		}
	}

	openNotificationDialog(filterComponent: FT_FilterComponent, userNotification?: UserNotification): void {
		console.debug(`${this.constructor.name} - openNotificationDialog`);

		// Close all open filter dialogs
		this.closeOpenDialogs();

		const panelClass = `ft-filter-panel-${this.layout.windowSize().toLowerCase()}`;

		// filter dialog component must include an input for appFilters
		const data: NotificationDialogData<unknown, UserNotification> = {
			title: `Notification`,
			message: `Enter notification settings`,
			filterComponent: filterComponent,
			filterComponentInputs: { appFilters: true },
			searchBarFields: [],
			userNotification: userNotification ?? undefined,
		};

		if (filterComponent) {
			this.removeActiveFocus();
			const dialogRef = this.dialog.open(UserNotificationDialog, { panelClass: panelClass, data });

			dialogRef
				.afterClosed()
				.pipe(
					takeUntilDestroyed(this.destroyRef),
					tap((result: { [key: string]: unknown } | undefined) => {
						console.debug(`${this.constructor.name} - openNotificationDialog - afterClosed - result=`, result);
						if (result?.['next']) {
						}
					}),
				)
				.subscribe();
		}
	} // end openViewDialog

	deepClone<T>(obj: T): T {
		if (obj === null || obj === undefined) {
			return obj;
		}

		if (typeof obj === 'string' || typeof obj === 'number' || typeof obj === 'boolean') {
			return obj;
		}

		if (obj instanceof Function) {
			return obj.bind(obj);
		}

		if (obj instanceof Date) {
			return new Date(obj) as T;
		}

		if (Array.isArray(obj)) {
			return obj.map(this.deepClone) as T;
		}

		if (typeof obj !== 'object') {
			return obj as T;
		}

		/**
		 * clone object
		 */
		const clonedObj: T = {} as T;
		for (const key in obj) {
			const keyOfT = key as keyof T;
			clonedObj[keyOfT] = this.deepClone(obj[key as keyof T]);
		}

		return clonedObj as T;
	}
} // end class
