import {
	type AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	type OnInit,
	effect,
	inject,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MAT_DATE_LOCALE } from '@angular/material/core';

import {} from '@angular/cdk/keycodes';
import { MatDialogModule } from '@angular/material/dialog';
import {} from 'rxjs';

import { FormlyFieldConfig, FormlyFormOptions, FormlyModule } from '@ngx-formly/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUserPen } from '@fortawesome/free-solid-svg-icons';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMatTextAreaModule } from '@ngx-formly/material/textarea';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { FormlySelectModule } from '@ngx-formly/core/select';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgClass } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { GetMutableObject } from '@ft/lib/core-lib';
import { FT_StandardComponent } from '@ft/lib/standard-types-lib';
import { ModeType, getHeaderTitleText, getSubmitTitleText } from '@furnas-technology/angular-library';
import { CloseIconButtonComponent } from '@furnas-technology/angular-library/components';
import { Artefact } from '../../data/artefact.model';
import { ArtefactService } from '../../data/artefact.service';
import { artefactEditCardFields } from './artefact-edit-card.formlyfields';

@Component({
	selector: 'ft-artefact-edit-card',
	templateUrl: './artefact-edit-card.component.html',
	styleUrls: ['./artefact-edit-card.component.scss'],
	providers: [{ provide: MAT_DATE_LOCALE, useValue: navigator.language ?? 'en-US' }],
	imports: [
		NgClass,
		FontAwesomeModule,
		FormlyMatDatepickerModule,
		FormlyModule,
		FormlyMaterialModule,
		FormlySelectModule,
		FormsModule,
		MatDatepickerModule,
		MatSelectModule,
		MatDialogModule,
		ReactiveFormsModule,
		CloseIconButtonComponent,
		DragDropModule,
		MatButtonModule,
		FormlyMatTextAreaModule,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArtefactEditCardComponent extends FT_StandardComponent<Artefact> implements OnInit, AfterViewInit {
	private cdr = inject(ChangeDetectorRef);
	protected artefactService = inject(ArtefactService);

	faUserPen = faUserPen;

	options: FormlyFormOptions = { formState: { addNew: false } };

	fields: FormlyFieldConfig[] = artefactEditCardFields;

	constructor() {
		super();

		this.recordName.set('Artefact');

		/**
		 * load data on input changes
		 */
		effect(() => {
			console.debug(`${this.constructor.name} - EFFECT - configId=${this.configId()}, modeType=${this.modeType()}`);
			const { obj: mutableObject, reference } = GetMutableObject(
				this.modeType(),
				this.configId(),
				Artefact,
				this.artefactService.store.selectDocumentById,
			);
			this.loadModel(mutableObject, reference);
		});

		effect(() => {
			this.options.formState.addNew = this.modeType() === 'Add New';
		});
	}

	loadModel(artefact: Artefact, reference: string): void {
		this.reference = reference;
		this.model.set(artefact);
		this.setHeaderTitle(artefact);

		this.cdr.detectChanges();
	} // end loadModel

	override setTitles(modeType: ModeType): void {
		// nothing to see here
		this.submitTitle.set(getSubmitTitleText(modeType));
		// this.headerTitle.set(getHeaderTitleText(modeType, this.recordName()));
	}

	setHeaderTitle(artefact: Artefact): void {
		let headerTitle = getHeaderTitleText(this.modeType(), 'Content');

		if (artefact.artefactName) {
			headerTitle += ` ${artefact.artefactName}`;
		} else {
			headerTitle += ` Artefact`;
		}

		this.headerTitle.set(headerTitle);
	}

	onModelChange(evt: unknown) {
		const curModel = this.model;
		const formKeys = Object.keys(this.form.controls);
		this.model = curModel;
	}
} // end class
