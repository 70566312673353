import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	type OnChanges,
	type OnInit,
	type SimpleChanges,
	effect,
	inject,
	input,
	viewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { AsyncPipe, NgClass } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BehaviorSubject, Subject } from 'rxjs';

import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { type NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';

import { ActiveFilterService } from '@ft/lib/active-filter-lib';
import { LayoutInfoService } from '@ft/lib/screen-lib';
import { AllFilters, FT_FilteredValues, FilterValue, GetFieldFilter } from '@furnas-technology/common-library/filters';

type SearchEvent = {
	term: string;
	items: string[];
};

const FILTER_DEBOUNCE_TIME = 300;
@Component({
	selector: 'ft-app-filter-select-field',
	templateUrl: './filter-select-field.component.html',
	styleUrls: ['./filter-select-field.component.scss'],
	imports: [
		AsyncPipe,
		FontAwesomeModule,
		FormsModule,
		MatButtonModule,
		MatCheckboxModule,
		MatDividerModule,
		MatFormFieldModule,
		NgClass,
		NgSelectModule,
		ReactiveFormsModule,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppFilterSelectFieldComponent implements OnInit, OnChanges {
	protected layout = inject(LayoutInfoService);
	private cdr = inject(ChangeDetectorRef);
	private afs = inject(ActiveFilterService);

	pluralText = input<string>('');
	loadingFlag = input<boolean>(false);
	fieldPath = input<string>('');
	operation = input<AllFilters>('equals');
	totalOptions = input<(string | number)[]>([]);
	hideTotals = input<boolean>(false);

	selectElem = viewChild<NgSelectComponent>('selectElem');

	submitTitle = 'Apply';

	fieldInput$ = new Subject<string>();
	fieldInput = toSignal(this.fieldInput$);
	availableOption$ = new BehaviorSubject<(string | number)[]>([]);
	selectedValues: FilterValue[] = [];

	constructor() {
		effect(() => {
			const filteredValues = FT_FilteredValues(this.fieldInput(), this.totalOptions());
			this.availableOption$.next(filteredValues);
		});
	}

	ngOnInit() {
		/**
		 * load initial filter values
		 */
		this.selectedValues = GetFieldFilter(this.fieldPath(), this.afs.selectActiveFieldFilters() ?? [])?.filterValues;
	}

	ngOnChanges(changes: SimpleChanges): void {}

	onSubmit() {
		// this.closeClicked.emit(true);
	}

	onSearch(evt: SearchEvent, fieldname: string) {}

	onClear() {}

	onOpen(fieldname: string): void {}

	onInputChange(fieldname: string) {
		if (fieldname === this.fieldPath()) {
			console.debug(
				`${this.constructor.name} - onInputChange - WATCH - fieldname=${fieldname}, selectedValues=`,
				this.selectedValues,
			);

			this.afs.updateFilterForFieldnames({
				fieldnames: fieldname,
				operation: this.operation(),
				filterValues: this.selectedValues as FilterValue[],
			});

			this.cdr.detectChanges(); // update view
		}
	}

	trackByFnString(item: string) {
		return item;
	}
}
