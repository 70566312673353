import { CreatableRow, MutableRow } from '@ft/lib/core-lib';

import { ApiCreateProp, ApiMutateProp } from '@ft/lib/core-lib';
import { CreateRowRequest, GetPagedDataRequest, MutateRowRequest } from '@ft/lib/crud-helper-lib';
import { StoreDefinition } from '@ft/lib/indexeddb-lib';
import { SignalStoreType, createSignalStore } from '@ft/lib/signal-store-lib';
import { ArtefactEditCardComponent } from '../components/artefact-edit-card/artefact-edit-card.component';
import { ApiUrlsArtefact } from '../data/apiurls.artefact';
import {
	getManyProperty,
	nameProperty,
	resultProperty,
	signalStoreName,
	sortProperty,
} from '../data/artefact.constants';
import { Artefact, ArtefactSearchBarFields } from '../data/artefact.model';

const getPagedDataRequest: GetPagedDataRequest<Artefact> = {
	getManyProperty: getManyProperty,
	getManyApi: ApiUrlsArtefact.getArtefacts(),
	chunkSize: 15,
};

const createRowRequest: CreateRowRequest<Artefact> = {
	apiCreate: (record: CreatableRow<Artefact>) => ApiUrlsArtefact.artefactCreateOne(record),
	apiCreateProp: ApiCreateProp,
	row: new Artefact(),
	notify: true,
	notifyKey: nameProperty,
};

const mutateRowRequest: MutateRowRequest<Artefact> = {
	apiMutate: (row: MutableRow<Artefact>) => ApiUrlsArtefact.artefactUpdateById(row._id, row as Artefact),
	apiMutateProp: ApiMutateProp,
	row: new Artefact(),
	notify: true,
	notifyKey: nameProperty,
};

const sortFunction = (a: Artefact, b: Artefact): number => {
	return (a.artefactName ?? '').toLocaleLowerCase().localeCompare((b.artefactName ?? '').toLocaleLowerCase());
};

const selectById = (artefact: Artefact) => artefact._id;

/**
 * Indexed DB defintions
 */

export const ARTEFACTS_STORE: StoreDefinition = {
	tableName: `Artefact`,
	keyPath: `_id`,
	autoIncrement: false,
	indexFields: `_id, artefactName, artefactType, updatedAt, retrievedAt`,
};

/**
 * Create signal store
 */
export const ArtefactStore: SignalStoreType<Artefact> = createSignalStore<Artefact>({
	signalStoreName: signalStoreName,
	getPagedDataRequest: getPagedDataRequest,
	createRowRequest: createRowRequest,
	mutateRowRequest: mutateRowRequest,
	apiDelete: ApiUrlsArtefact.artefactDeleteById,
	editComponent: ArtefactEditCardComponent,
	editResultProperty: resultProperty,
	nameProperty: nameProperty,
	sortProperty: sortProperty,
	searchBarFields: ArtefactSearchBarFields,
	loadOn: 'auth',
	classConstructor: Artefact,

	indexedDBConfig: {
		indexedDBName: 'artefacts',
		indexedDBVersion: 2.6,
		indexedDBStoreList: [ARTEFACTS_STORE],
	},
});
