import {
	type AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	Inject,
	type OnInit,
	inject,
	signal,
} from '@angular/core';

import {} from '@angular/cdk/keycodes';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import {} from 'rxjs';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCircleUser, faTrash } from '@fortawesome/free-solid-svg-icons';

import { MatButtonModule } from '@angular/material/button';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { PromptService } from '@ft/lib/dialogs-lib';
import {} from '@furnas-technology/angular-library/components';
import ArtefactDialogModel, { Artefact } from '../../data/artefact.model';
import { ArtefactService } from '../../data/artefact.service';
import { ArtefactArticleViewComponent } from '../artefact-article-view/artefact-article-view.component';

@Component({
	selector: 'ft-artefact-dialog',
	templateUrl: './artefact-dialog.component.html',
	styleUrls: ['./artefact-dialog.component.scss'],
	providers: [],
	imports: [ArtefactArticleViewComponent, DragDropModule, FontAwesomeModule, MatButtonModule, MatDialogModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArtefactDialog implements OnInit, AfterViewInit {
	protected destroyRef = inject(DestroyRef);
	private cdr = inject(ChangeDetectorRef);
	dialogRef = inject(MatDialogRef<ArtefactDialog, Artefact>);
	prompt = inject(PromptService);
	artefactService = inject(ArtefactService);

	faCircleUser = faCircleUser;
	faTrash = faTrash;

	selectedArtefact = signal<Artefact | null>(null);

	headerTitle: string = '';
	submitTitle: string = '';

	constructor(@Inject(MAT_DIALOG_DATA) public artefactDialog: ArtefactDialogModel) {}

	async ngOnInit() {
		// get artefact
		const artefact = this.artefactService.store.selectDocumentById(this.artefactDialog.id);
		this.selectedArtefact.set(artefact ?? null);
		this.setHeaderTitle(artefact);

		this.submitTitle = 'Close';
	}

	setHeaderTitle(artefact: Artefact | undefined | null): void {
		this.headerTitle = artefact ? artefact.artefactName : '';
	}

	ngAfterViewInit() {
		// nothing to see here
	}

	onSubmit() {
		this.close();
	} // end onSubmit

	close() {
		this.dialogRef.close();
	}
} // end class
