<div class="management-component mat-app-background">
  <!-- loading bar -->
  <ft-progress-bar [isLoading]="rowsLoading()"></ft-progress-bar>
  <!-- page controls -->
  <div class="page-controls">
    <!-- page buttons -->
    <div class="page-buttons">
      <div class="button-instance">
        @if (authStore.isAdmin()) {
          <ft-add-button addText="Add new content" (addClicked)="createArtefact()"></ft-add-button>
        }
      </div>
    </div>
    <!-- page search bar -->
    <div class="page-filter"></div>
    <!-- page totals -->
    <ft-page-totals
      title="Artefacts"
      [showing]="rowsShowing()"
      [selectLoaded]="rowsLoaded()"
      [selectTotal]="totals()"
    ></ft-page-totals>
  </div>
  <!-- table content -->
  <div class="page-content">
    <!-- <div class="testing"> -->
    @if (visibleRows()) {
      <section class="table-container mat-elevation-z8" tabindex="0">
        <ft-display-table
          [columns]="columns"
          [databaseRows]="visibleRows()"
          (buttonPressed)="handleButtonPressed($event)"
          rowId="_id"
          (filteredRowCount)="rowsShowing.set($event)"
        ></ft-display-table>
      </section>
    }
  </div>
</div>
