import { ChangeDetectionStrategy, Component, type OnInit, computed, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import { PageTotalsComponent } from '@ft/lib/components';
import { DisplayTableComponent, TableButtonPressed, TableColumn } from '@ft/lib/tables-lib';
import { AddButtonComponent, ProgressBarComponent } from '@furnas-technology/angular-library/components';

import { AuthStore } from '@ft/lib/auth-lib';
import { Artefact } from '../../data/artefact.model';
import { ArtefactService } from '../../data/artefact.service';

@Component({
	selector: 'artefact-management',
	templateUrl: './artefact-management.component.html',
	styleUrls: ['./artefact-management.component.scss'],
	imports: [
		ProgressBarComponent,
		FontAwesomeModule,
		MatButtonModule,
		MatDialogModule,
		MatDatepickerModule,
		MatNativeDateModule,
		DisplayTableComponent,
		AddButtonComponent,
		PageTotalsComponent,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArtefactManagementComponent implements OnInit {
	protected authStore = inject(AuthStore);
	private artefactService = inject(ArtefactService);

	faEdit = faEdit;
	faTrash = faTrash;

	displayedColumns: string[] = [];
	stickyColumns: string[] = [];

	columns: TableColumn<Artefact>[] = [];

	rowsShowing = signal(0);
	rowsLoaded = computed(() => this.artefactService.documentsLoaded());
	rowsLoading = computed(() => this.artefactService.isLoading());
	visibleRows = computed(() => this.artefactService.filteredDocumentsNotOmitted());
	totals = computed(() => this.artefactService.totals()?.count ?? 0);

	constructor() {
		console.debug(`${this.constructor.name} - constructor`);
	}

	ngOnInit(): void {
		const columns: TableColumn<Artefact>[] = [];
		columns.push(
			new TableColumn({
				name: '_id',
				columnType: 'text',
				label: '_id',
				hidden: true,
				hideShow: false,
				wrapText: false,
			}),
		);
		columns.push(
			new TableColumn({
				name: 'artefactName',
				columnType: 'text',
				label: 'Name',
				hidden: false,
				sticky: true,
				maxWidth: 300,
				minWidth: 200,
				required: true,
				wrapText: false,
			}),
		);
		columns.push(
			new TableColumn({
				name: 'artefactType',
				columnType: 'text',
				label: 'Type',
				hidden: false,
				minWidth: 40,
				wrapText: false,
			}),
		);

		columns.push(
			new TableColumn({
				name: 'desc',
				columnType: 'text',
				label: 'Description',
				hidden: false,
				minWidth: 200,
				maxWidth: 400,
				wrapText: true,
			}),
		);
		columns.push(
			new TableColumn({
				name: 'updatedAt',
				columnType: 'date',
				format: 'date-ago',
				label: 'Updated Date',
				hidden: false,
				wrapText: false,
				minWidth: 80,
				width: 80,
				tooltipColumn: 'updatedAt',
			}),
		);
		columns.push(
			new TableColumn({
				name: 'edit',
				columnType: 'actions',
				stickyEnd: true,
				hidden: false,
				label: 'Options',
				tableButtons: [
					{
						buttonType: 'button',
						buttonName: 'Edit',
						buttonIcon: faEdit,
					},
					{ buttonType: 'button', buttonName: 'Delete', buttonIcon: faTrash },
				],
			}),
		);

		this.columns = columns;
	}

	/** Whether the button toggle group contains the id as an active value. */
	isSticky(id: string) {
		return this.stickyColumns.indexOf(id) !== -1;
	}

	createArtefact() {
		this.artefactService.editDocument({ id: '', modeType: 'Add New' });
	} // end creatArtefact

	handleButtonPressed(buttonPressed: TableButtonPressed<Artefact>) {
		if (!buttonPressed) return;

		if (buttonPressed.tableAction?.buttonType === 'button') {
			if (buttonPressed._id) {
				if (buttonPressed.tableAction.buttonName?.match(/^Edit$/i)) {
					this.editArtefact(buttonPressed._id);
				} else if (buttonPressed.tableAction.buttonName?.match(/^Delete$/i)) {
					this.deleteArtefact(buttonPressed._id);
				}
			} // end if _id
		} // end if button
	} // end handleButtonPressed

	/**
	 * Edit row selected on table
	 */
	async editArtefact(id: string) {
		if (!id) return;

		// need to select an artefact here
		this.artefactService.store.setSelectedId(id);
		this.artefactService.editDocument({ id: id, modeType: 'Edit' });
	} // end editArtefact

	deleteArtefact(id: string) {
		if (!id) return;
		this.artefactService.deleteDocument({ id: id });
	}
}
