import { CreatableRow, MutableRow } from '@ft/lib/core-lib';

import { ApiCreateProp, ApiMutateProp } from '@ft/lib/core-lib';
import { CreateRowRequest, GetPagedDataRequest, MutateRowRequest } from '@ft/lib/crud-helper-lib';
import { StoreDefinition } from '@ft/lib/indexeddb-lib';
import { SignalStoreType, createSignalStore } from '@ft/lib/signal-store-lib';
import { UserNotificationCardComponent } from '../components/user-notification-card/user-notification-card.component';
import { ApiUrlsUserNotification } from '../data/apiurls.user-notifications';
import {
	getManyProperty,
	nameProperty,
	resultProperty,
	signalStoreName,
	sortProperty,
} from '../data/user-notification.constants';
import { UserNotification, UserNotificationSearchBarFields } from '../data/user-notification.model';

const getPagedDataRequest: GetPagedDataRequest<UserNotification> = {
	getManyProperty: getManyProperty,
	getManyApi: ApiUrlsUserNotification.getNotifications(),
	chunkSize: 100,
	// searchFilterTypes: ['username'],
};

const createRowRequest: CreateRowRequest<UserNotification> = {
	apiCreate: (record: CreatableRow<UserNotification>) => ApiUrlsUserNotification.notificationCreateOne(record),
	apiCreateProp: ApiCreateProp,
	row: new UserNotification({}),
	notify: true,
	notifyKey: nameProperty,
};

const mutateRowRequest: MutateRowRequest<UserNotification> = {
	apiMutate: (row: MutableRow<UserNotification>) =>
		ApiUrlsUserNotification.notificationUpdateOne(row._id, row as UserNotification),
	apiMutateProp: ApiMutateProp,
	row: new UserNotification({}),
	notify: true,
	notifyKey: nameProperty,
};

const sortFunction = (a: UserNotification, b: UserNotification): number => {
	return (a.notificationName ?? '').toLocaleLowerCase().localeCompare((b.notificationName ?? '').toLocaleLowerCase());
};

const selectById = (userNotification: UserNotification) => userNotification._id;

/**
 * Indexed DB defintions
 */
const USER_NOTIFICATIONS_STORE: StoreDefinition = {
	tableName: `UserNotifications`,
	keyPath: '_id',
	autoIncrement: false,
	indexFields: `_id, updatedAt, retrievedAt `,
};

/**
 * Create signal store
 */
export const UserNotificationStore: SignalStoreType<UserNotification> = createSignalStore<UserNotification>({
	signalStoreName: signalStoreName,
	getPagedDataRequest: getPagedDataRequest,
	createRowRequest: createRowRequest,
	mutateRowRequest: mutateRowRequest,
	apiDelete: ApiUrlsUserNotification.notificationDeleteOneById,
	editComponent: UserNotificationCardComponent,
	editResultProperty: resultProperty,
	nameProperty: nameProperty,
	sortProperty: sortProperty,
	searchBarFields: UserNotificationSearchBarFields,
	loadOn: 'subscribe',
	classConstructor: UserNotification,

	indexedDBConfig: {
		indexedDBName: 'user_notifications',
		indexedDBVersion: 1.6,
		indexedDBStoreList: [USER_NOTIFICATIONS_STORE],
	},
});
