import { CreatableRow, MutableRow } from '@ft/lib/core-lib';
import { ModeType } from '@furnas-technology/angular-library';
import {
	AllFilters,
	DefaultNotificationFilters,
	FilterDataType,
	FilterValue,
	FilteredRecord,
	NotificationFilters,
	SearchBarField,
} from '@furnas-technology/common-library/filters';

import { FT_LogError, FT_copyNonNulls } from '@furnas-technology/common-library/functions';

export const CreateNotificationName = (notificationFilters: NotificationFilters): string => {
	const filterNames = [];
	if (notificationFilters.searchString) filterNames.push(notificationFilters.searchString);
	if (notificationFilters.fieldFilters.length) {
		for (const fieldFilter of notificationFilters.fieldFilters) {
			if (fieldFilter.fieldnames?.length) filterNames.push(fieldFilter.fieldnames[0]);
		}
	}

	if (filterNames.length) {
		return filterNames.join(', ');
	} else {
		return `Notification ${new Date().toISOString().slice(0, 10)}`;
	}
};

export type UserNotificationSchedule = 'daily' | 'weekly' | 'immediately' | 'none';

export interface UserNotificationDialogModel {
	modeType: ModeType;
	id: string;
}

export type NotificationFilter = {
	fieldname: string;
	operation: AllFilters;
	filterValues: FilterValue[];
	filterDataType: FilterDataType;
};

export class UserNotification {
	_id: string = '';
	username: string = '';
	notificationName: string = '';
	schedule: UserNotificationSchedule = 'none';
	notificationFilters: NotificationFilters = DefaultNotificationFilters;

	deleted: boolean = false;
	createdAt: string = '';
	updatedAt: string = '';
	retrievedAt: string = '';

	constructor(init: Partial<UserNotification>) {
		try {
			FT_copyNonNulls(this, init);
		} catch (err: unknown) {
			FT_LogError(err, `UserNotification`);
		}
	}
} // end Notification

export type UserNotificationMutateRecord = MutableRow<UserNotification>;
export type UserNotificationCreateRecord = CreatableRow<UserNotification>;

/**
 * create records from objects
 */
export const UserNotificationSearchBarFields: SearchBarField<UserNotification>[] = [
	new SearchBarField<UserNotification>('notificationName', ['string']),
	new SearchBarField<UserNotification>('schedule', ['string']),
];

export type FilteredUserNotification = FilteredRecord<UserNotification>;
