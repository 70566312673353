import { FormlyFieldConfig } from '@ngx-formly/core';
import { ArtefactTypeValues } from '../../data/artefact.model';

const hideExtras = (field: FormlyFieldConfig): boolean => {
	if (field.form?.value.artefactType === 'Background') {
		return field.key !== 'artefactImages';
	}

	if (!field.form?.value.artefactType) {
		return ['lede', 'artefactImages', 'artefactPdfs', 'richText', 'videoHtml'].includes(String(field.key));
	}

	if (field.form?.value.artefactType === 'Video') {
		return !['videoHtml'].includes(String(field.key));
	}

	if (field.form?.value.artefactType !== 'Video') {
		return ['videoHtml'].includes(String(field.key));
	}

	return false;
};

export const artefactEditCardFields: FormlyFieldConfig[] = [
	{
		fieldGroupClassName: 'display-flex-row',
		fieldGroup: [
			{
				className: 'flex-7 no-wrap sticky',
				type: 'input',
				key: 'artefactName',
				props: {
					label: 'Artefact Name:',
					flex: true,
					readonly: false,
					valueClass: 'strong',
					required: true,
				},
				expressions: {
					// 'props.disabled': '!model.competition.competitionName',
				},
			},
			{
				className: 'flex-3 pre-line',
				type: 'select',
				key: 'artefactType',
				props: {
					label: 'Type',
					flex: true,
					readonly: false,
					valueClass: 'strong',
					required: true,
					options: ArtefactTypeValues.filter((v) => !!v).map((v) => ({ label: v, value: v })),
				},
				expressions: {
					// 'props.disabled': '!model.competition.competitionName',
				},
			},
			{
				className: 'flex-1 no-wrap sticky',
				type: 'input',
				key: 'backgroundColor',
				props: {
					label: 'Background Color:',
					flex: true,
					readonly: false,
					valueClass: 'normal',
					required: false,
				},
				expressions: {
					// 'props.disabled': '!model.competition.competitionName',
				},
			},
		],
	},

	{
		fieldGroupClassName: 'display-flex-row',
		fieldGroup: [
			{
				className: 'flex-3 pre-line',
				type: 'input',
				key: 'desc',
				props: {
					label: 'Short description:',
					flex: true,
					readonly: false,
					valueClass: 'strong',
					required: true,
				},
				expressions: {
					// 'props.disabled': '!model.competition.competitionName',
				},
			},
		],
	},

	{
		fieldGroupClassName: 'display-flex-row',
		fieldGroup: [
			{
				className: 'flex-3 pre-line',
				type: 'input',
				key: 'videoHtml',
				props: {
					label: 'Video html code:',
					flex: true,
					readonly: false,
					valueClass: 'strong',
					required: true,
				},
				expressions: {
					hide: (field: FormlyFieldConfig) => {
						return hideExtras(field);
					},
				},
			},
		],
	},

	{
		fieldGroupClassName: 'display-flex-row',
		fieldGroup: [
			{
				className: 'flex-3 pre-line',
				type: 'quill',
				key: 'lede',
				props: {
					label: 'Excerpt',
					flex: true,
					readonly: false,
					valueClass: 'strong',
					required: false,
					disabled: false,
				},
				expressions: {
					hide: (field: FormlyFieldConfig) => {
						return hideExtras(field);
					},
				},
			},
		],
	},

	{
		key: 'artefactImages',
		type: 'repeat',
		className: 'flex-1 artefact-images  flex-row',
		props: {
			addText: 'Add new image',
			label: 'Images',
		},
		expressions: {
			hide: (field: FormlyFieldConfig) => {
				return hideExtras(field);
			},
		},

		fieldArray: {
			className: 'flex-0 ft-array-field image-upload flex-row',
			type: 'image-upload',
			props: {
				label: 'Image',
				placeholder: 'Image',
				readonly: false,
				height: '56px',
				width: '64px',
				uploadType: 'image',
			},
		},
	},

	{
		key: 'artefactPdfs',
		type: 'repeat',
		className: 'flex-1 artefact-pdfs  flex-row',
		props: {
			addText: 'Add new PDF',
			label: 'PDFs',
		},
		expressions: {
			hide: (field: FormlyFieldConfig) => {
				return hideExtras(field);
			},
		},

		fieldArray: {
			className: 'flex-0 ft-array-field image-upload flex-row',
			type: 'image-upload',
			props: {
				label: 'PDF',
				placeholder: 'PDF',
				readonly: false,
				height: '56px',
				width: '64px',
				uploadType: 'pdf',
			},
		},
	},

	{
		fieldGroupClassName: 'display-flex-row',
		fieldGroup: [
			{
				className: 'flex-3 pre-line',
				type: 'quill',
				key: 'richText',
				props: {
					label: 'Main Content',
					flex: true,
					readonly: false,
					valueClass: 'strong',
					required: false,
					disabled: false,
				},
				expressions: {
					hide: (field: FormlyFieldConfig) => {
						return hideExtras(field);
					},
				},
			},
		],
	},
];
