<!-- <div class="ft-dialog-container"> -->
<div
  class="ft-dialog-component filter-dialog-component"
  cdkDrag
  [cdkDragDisabled]="layout.isLT_small()"
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [ngClass]="layout.cssClasses()"
>
  <div mat-dialog-title class="filter-header">
    <!-- card header  -->
    <div class="title-container">
      <div class="title-text">
        <fa-icon [icon]="faSliders"></fa-icon>
        <h4>{{ title }}</h4>
      </div>

      <div class="title-options">
        <ft-close-button (closeClicked)="close()"></ft-close-button>
      </div>
    </div>
  </div>

  <mat-dialog-content class="hide-scrollbars filter-content" #dialogContent>
    @if (afs.selectActiveSearchBarInFilterComponent()) {
      <ft-search-bar class="search-bar"></ft-search-bar>
    }
    @if (!!filterComponent()) {
      <ng-container *ngComponentOutlet="filterComponent(); inputs: dialogData.filterComponentInputs ?? {}" />
      <div id="filterhost"></div>
    }
  </mat-dialog-content>

  <!-- card actions  -->
  <mat-dialog-actions class="ft-dialog-actions filter-actions">
    <button type="submit" mat-button class="submit-button" (click)="close()">
      {{ submitTitle() }}
    </button>
    @if (authStore.isSubscribed()) {
      <button
        type="button"
        mat-button
        class="action-button"
        (click)="createNotification($event)"
        aria-label="Create Notification"
      >
        {{ createNotificationText }}
      </button>
    }
  </mat-dialog-actions>
</div>
