import {
	AfterViewInit,
	ApplicationRef,
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	ElementRef,
	EnvironmentInjector,
	Inject,
	type OnInit,
	Type,
	ViewContainerRef,
	inject,
	output,
	signal,
	viewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CdkDrag } from '@angular/cdk/drag-drop';

import { MatButtonModule } from '@angular/material/button';
import { faSliders } from '@fortawesome/free-solid-svg-icons';

import { NgClass, NgComponentOutlet } from '@angular/common';
import { ActiveFilterService, FT_FilterComponent, FT_FilterComponentInputs } from '@ft/lib/active-filter-lib';
import { AuthStore } from '@ft/lib/auth-lib';
import { SearchBarComponent } from '@ft/lib/filter-searchbar';
import { LayoutInfoService } from '@ft/lib/screen-lib';
import { CloseIconButtonComponent } from '@furnas-technology/angular-library/components';
import { FieldFilter, SearchBarField } from '@furnas-technology/common-library/filters';

export interface FT_FilterDialogData<CompType, T> {
	title: string;
	message: string;
	filterComponent: FT_FilterComponent;
	filterComponentInputs: FT_FilterComponentInputs<CompType>;
	searchBarFields: SearchBarField<T>[];
}

@Component({
	selector: 'ft-filter-dialog',
	templateUrl: './filter-dialog.component.html',
	styleUrls: ['./filter-dialog.component.scss'],
	imports: [
		CdkDrag,
		CloseIconButtonComponent,
		FontAwesomeModule,
		FormsModule,
		MatDialogModule,
		MatButtonModule,
		NgClass,
		NgComponentOutlet,
		ReactiveFormsModule,
		SearchBarComponent,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FT_FilterDialog implements OnInit, AfterViewInit {
	protected destroyRef = inject(DestroyRef);
	protected layout = inject(LayoutInfoService);

	vcr = inject(ViewContainerRef);
	appRef = inject(ApplicationRef);
	envInjector = inject(EnvironmentInjector);

	authStore = inject(AuthStore);

	dialogRef = inject(MatDialogRef<FT_FilterDialog>);
	protected afs = inject(ActiveFilterService);

	updatedFieldFilters = output<FieldFilter<unknown>[]>();

	dialogContent = viewChild<ElementRef>('dialogContent');

	filterComponent = signal<Type<unknown> | null>(null);

	isLoading = false;
	form = new FormGroup({});

	submitTitle = signal('Apply');
	title: string = '';
	createNotificationText = '🔔 Create Notification';

	faSliders = faSliders;

	testFilterComp: Type<unknown> | undefined;

	constructor(@Inject(MAT_DIALOG_DATA) public dialogData: FT_FilterDialogData<unknown, unknown>) {
		console.debug(`${this.constructor.name} - constructor`);
	}

	ngOnInit() {
		this.title = this.dialogData.title;
	}

	ngAfterViewInit(): void {
		console.debug(`${this.constructor.name} - ngAfterViewInit`);
		if (this.dialogData.filterComponent) {
			this.filterComponent.set(this.dialogData.filterComponent);
		}
	}

	onSubmit() {
		this.dialogRef.close();
	}

	close() {
		this.dialogRef.close();
	}

	createNotification(event: MouseEvent | PointerEvent) {
		this.dialogRef.close({ next: `createNotification` });
	}
}
