<div class="artefact-article-view-component" [ngClass]="layout.cssClasses()">
  <main class="artefact-article-main mat-elevation-z8 mat-accent mat-app-background-95">
    <title>{{ artefact()?.artefactName ?? 'unknown' }}</title>
    <!-- <header>my header</header> -->
    <article #artefactcard>
      @if (artefact()) {
        <header class="artefact-article-header">
          <h2 class="artefact-name">{{ artefact()?.artefactName }}</h2>
          @if (artefact()?.updatedAt) {
            <aside class="updated">Updated: {{ artefact()?.updatedAt | dateAgo }}</aside>
          }
        </header>
        <div id="artical-container" class="artefact-article-container hide-scrollbars">
          @if (carouselImages().length > 0) {
            <div class="images">
              <ft-image-carousel [images]="carouselImages()"></ft-image-carousel>
            </div>
          }
          @if (artefact()?.artefactType === 'Video') {
            <div class="video-container" [ngClass]="layout.cssClasses()" loadingBackground>
              <ft-display-embedded-video [embeddedHtml]="artefact()?.videoHtml ?? ''"></ft-display-embedded-video>
            </div>
          } @else if (artefactContent()) {
            <div class="content">
              <quill-view-html
                class="artefact-rich-text"
                [ngClass]="layout.cssClasses()"
                [content]="artefactContent()"
                theme="snow"
                [sanitize]="false"
              ></quill-view-html>
            </div>
          }
          @if (artefact()?.artefactPdfs?.length) {
            <div class="pdfs">
              @for (pdf of artefact()?.artefactPdfs; track pdfIndex; let pdfIndex = $index) {
                <div class="pdf-container">
                  <ft-pdf-viewer
                    style="min-height: 5px"
                    class="pdf"
                    [height]="'100%'"
                    [width]="'100%'"
                    [showAll]="true"
                    [originalSize]="false"
                    [fitToPage]="true"
                    [pdfSrc]="pdf"
                    [zoomScale]="'page-width'"
                  ></ft-pdf-viewer>
                </div>
              }
            </div>
          }
        </div>
      }
    </article>
  </main>
</div>
