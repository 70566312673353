import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FilterArrayValue } from '@ft/lib/screen-lib';

@Component({
	selector: 'filter-select',
	templateUrl: './filter-select.component.html',
	styleUrls: ['./filter-select.component.scss'],
	imports: [MatFormFieldModule, MatSelectModule, MatButtonModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterSelectComponent implements OnInit {
	destroyRef = inject(DestroyRef);

	@Input() title?: string = '';
	@Input() label?: string = '';
	@Input() filterArray!: FilterArrayValue[];

	@Output() selectedFilter: EventEmitter<FilterArrayValue> = new EventEmitter<FilterArrayValue>();

	selectedName?: string;

	constructor() {}

	ngOnInit() {
		const selectedItem = this.filterArray.find((item) => item.selected);
		if (selectedItem) {
			this.selectedName = selectedItem.name;
		}
	}

	selectionChanged(selectedName: string | undefined) {
		console.debug(`selectionChanged - selectedName=`, selectedName);
		const selectedFilter = this.filterArray.find((item) => item.name === selectedName);
		this.selectedFilter.emit(selectedFilter);
	} // end selectionChanged
}
