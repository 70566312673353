@if (filterArray) {
<div class="filter-select">
  @if (title) {
  <h4>{{ title }}</h4>
  }
  <mat-form-field appearance="fill">
    @if (label) {
    <mat-label>{{ label }}</mat-label>
    }
    <mat-select [(value)]="selectedName" (selectionChange)="selectionChanged(selectedName)">
      @for (row of filterArray; track row) {
      <mat-option mat-option [value]="row.name">
        {{ row.name }}
      </mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
}
