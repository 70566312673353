import { DestroyRef, Injectable, computed, inject } from '@angular/core';

import { GenericStoreService, GenericStoreServiceInput } from '@ft/lib/store-service-lib';
import { ModeType } from '@furnas-technology/angular-library';
import { tap } from 'rxjs';
import { UserNotification } from './user-notification.model';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FT_FilterComponent } from '@ft/lib/active-filter-lib';
import { AuthStore } from '@ft/lib/auth-lib';
import { CommonDialogService } from '@ft/lib/dialogs-lib';
import { LayoutInfoService } from '@ft/lib/screen-lib';
import { UserNotificationCardComponent } from '../components/user-notification-card/user-notification-card.component';
import {
	NotificationDialogData,
	UserNotificationDialog,
} from '../components/user-notification-dialog/user-notification-dialog.component';
import { UserNotificationListComponent } from '../components/user-notification-list/user-notification-list.component';
import { UserNotificationStore } from '../store/user-notification.store';
import { nameProperty, resultProperty, signalStoreName } from './user-notification.constants';

const genericStoreServiceInput: GenericStoreServiceInput<UserNotification> = {
	signalStoreName: signalStoreName,
	signalStore: UserNotificationStore,

	viewComponent: UserNotificationCardComponent,
	editComponent: UserNotificationCardComponent,
	resultProperty: resultProperty,
	nameProperty: nameProperty,
};

export interface UserNotificationQueryResult {
	userCount: number;
	userMany?: UserNotification[];
}

export type UserNotificationUpdateResult = {
	userNotification: UserNotification;
	modeType: ModeType;
};

@Injectable({
	providedIn: 'root',
})
export class UserNotificationService extends GenericStoreService<UserNotification> {
	readonly store = inject(UserNotificationStore);
	protected destroyRef = inject(DestroyRef);
	protected layout = inject(LayoutInfoService);
	private dialogService = inject(CommonDialogService);
	private authStore = inject(AuthStore);

	/**
	 * store selector
	 */
	public userNotificationStore = computed(() => {
		return this.store;
	});

	buttonIcon = faBell;
	/**
	 * Notifications  button
	 */
	notificationTooltip = computed(() => {
		return `View your notifications`;
	});

	notificationText = computed(() => {
		return 'Notifications';
	});

	notificationDialog = computed(() => {
		return () => this.openNotificationList();
	});

	notificationButtonDisabled = computed(() => {
		return !this.authStore.isSubscribed();
	});

	constructor() {
		super(genericStoreServiceInput);
		console.debug(`${this.constructor.name} - constructor - ${genericStoreServiceInput.signalStoreName}`);
	}

	/**
	 * Open dialog
	 */
	openNotificationDialog(filterComponent?: FT_FilterComponent, userNotification?: UserNotification): void {
		console.debug(`${this.constructor.name} - openNotificationDialog`);

		const dialogComponent = filterComponent ?? this.afs.selectActiveComponentFilter()?.component;
		if (!dialogComponent) return;

		const panelClass = `ft-filter-panel-${this.layout.windowSize().toLowerCase()}`;

		// filter dialog component must include an input for appFilters
		const data: NotificationDialogData<unknown, UserNotification> = {
			title: `Notification`,
			message: `Enter notification settings`,
			filterComponent: dialogComponent,
			filterComponentInputs: { appFilters: true },
			searchBarFields: [],
			userNotification: userNotification ?? undefined,
		};

		const dialogRef = this.dialog.open(UserNotificationDialog, { panelClass: panelClass, data });
		dialogRef.afterClosed().pipe(
			takeUntilDestroyed(this.destroyRef),
			tap((result: { [key: string]: unknown } | undefined) => {
				console.debug(`${this.constructor.name} - openNotificationDialog - afterClosed - result=`, result);
			}),
		);
	}

	/**
	 * User notification list
	 */
	openNotificationList() {
		const panelClass = `user-notification-dialog`;

		const width = this.layout.isMobile() || this.layout.isLT_small() ? '98vw' : '840px';
		const maxWidth = '98vw';

		this.dialogService.openDialog(UserNotificationListComponent, {
			panelClass: 'user-notification-dialog',
			width: width,
			maxWidth: maxWidth,
			data: { closeOthers: true },
		});
	}
} // end class
