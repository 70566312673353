<form class="app-filter-form-component" (ngSubmit)="onSubmit()" [ngClass]="layout.cssClasses()">
  <!-- artefact type -->
  <section class="section">
    <ft-app-filter-select-field
      class="filter-field select-field"
      pluralText="Content types"
      fieldPath="artefactType"
      operation="equals"
      [loadingFlag]="rowsLoading()"
      [totalOptions]="this.artefactService.artefactTypes()"
    ></ft-app-filter-select-field>
  </section>

  <!-- artefact name -->
  <section class="section">
    <ft-app-filter-select-field
      class="filter-field select-field"
      pluralText="Content names"
      fieldPath="artefactName"
      operation="equals"
      [loadingFlag]="rowsLoading()"
      [totalOptions]="this.artefactService.artefactNames()"
    ></ft-app-filter-select-field>
  </section>
</form>
