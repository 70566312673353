import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	ElementRef,
	OnInit,
	computed,
	inject,
	input,
	output,
	signal,
	viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { NgClass } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BehaviorSubject, debounceTime, tap } from 'rxjs';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';

import { faCircleQuestion, faXmark } from '@fortawesome/free-solid-svg-icons';

import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActiveFilterService } from '@ft/lib/active-filter-lib';
import { GlobalStore } from '@ft/lib/global-lib';
import { LayoutInfoService } from '@ft/lib/screen-lib';

const DEBOUNCE_TIME = 700;

const sanitiseString = (searchString: string | undefined) => {
	return String(searchString ?? '').trim();
};

@Component({
	selector: 'ft-search-bar',
	templateUrl: './search-bar.component.html',
	styleUrls: ['./search-bar.component.scss'],
	imports: [
		NgClass,
		FontAwesomeModule,
		MatFormFieldModule,
		MatInputModule,
		FormsModule,
		MatButtonModule,
		MatTooltipModule,
		ReactiveFormsModule,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBarComponent implements OnInit, AfterViewInit {
	private destroyRef = inject(DestroyRef);
	protected layout = inject(LayoutInfoService);
	private cdr = inject(ChangeDetectorRef);
	private gss = inject(GlobalStore);

	private afs = inject(ActiveFilterService);

	globalSearch = input<boolean>(true);
	initialSearchString = input<string | undefined>(undefined);
	backgroundColor = input<string>('');
	searchTerm = output<string>();
	escapeKey = output<KeyboardEvent>();

	searchInput = viewChild<ElementRef<unknown>>('searchInput');
	submitButton = viewChild<ElementRef<unknown>>('submitButton');

	inputControl = new FormControl('');
	prevSearchString = '';

	faXmark = faXmark;
	faCircleQuestion = faCircleQuestion;

	private debounceSearch$ = new BehaviorSubject<string>('');

	searchString = signal<string>('');
	separatorKeysCodes: number[] = [ENTER, COMMA];

	searchbarHeight = computed(() => {
		return `calc( ${this.gss.navbarHeight()} - 8px)`;
	});

	displayedSearchString = computed(() => {
		return this.globalSearch() ? this.afs.selectActiveSearchString() : this.searchString();
	});

	backgroundColorStyle = computed(() => {
		if (this.backgroundColor() && typeof this.backgroundColor() === 'string' && this.backgroundColor().length > 0) {
			return this.backgroundColor();
		}
		return this.layout.uiMode() === 'darkMode' ? 'rgba(200, 200, 200, 0.5)' : 'rgba(100, 100, 100, 0.2)';
		// 'rgba(10, 11, 12, 0.1)'
		// return `background-color:${this.backgroundColor()}`;
	});

	tooltipText =
		`This is an intelligent search bar. Enter quotes around text that contains spaces, enter years to search only for matching years.  ` +
		`\nFor example, to search for Dec 2025, you can enter "Dec 2025" or 2025-12. `;

	constructor() {
		/**
		 * Monitor changes to search bar and update store when it changes
		 */
		this.debounceSearch$
			.asObservable()
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				debounceTime(DEBOUNCE_TIME),
				tap((searchString: string) => {
					const sanitisedString = sanitiseString(searchString);
					if (sanitisedString !== this.prevSearchString) {
						this.prevSearchString = sanitisedString;
						if (this.globalSearch()) {
							this.afs.setActiveSearchString(sanitisedString);
						} else {
							this.searchString.set(sanitisedString);
						}

						this.searchTerm.emit(sanitisedString);
						this.cdr.detectChanges();
					}
				}),
			)
			.subscribe();
	}

	ngOnInit() {
		console.debug(
			`${
				this.constructor.name
			} - ngOnInit - globalSearch=${this.globalSearch()}, initialSearchString=${this.initialSearchString()}`,
		);

		if (this.globalSearch()) {
			this.prevSearchString = this.afs.selectActiveSearchString();
			this.debounceSearch$.next(this.prevSearchString);
		} else {
			const initialSearchString = this.initialSearchString() ?? '';
			this.searchString.set(initialSearchString);
			this.prevSearchString = `${initialSearchString}x`;
			this.debounceSearch$.next(initialSearchString);
		}
	}

	ngAfterViewInit(): void {
		// nothing to see here
	}

	/**
	 * Clear search bar immediately, no debounce
	 */
	onClear(): void {
		this.prevSearchString = '';
		if (this.globalSearch()) {
			this.afs.setActiveSearchString('');
		} else {
			this.searchString.set('');
		}
		this.searchTerm.emit('');
	} // end clearSearch

	onPaste(evt: ClipboardEvent) {
		const clipboardData = evt.clipboardData;
		const pastedText = clipboardData ? clipboardData.getData('text') : '';
	} // end onPaste

	onSearchChange(evt: Event) {
		this.debounceSearch$.next(this.searchString());
	}

	onKeyup(evt: Event) {
		evt.stopPropagation();
	}

	onKeydown(evt: Event) {
		evt.stopPropagation();
	}

	/**
	 * handle search term change
	 */
	updateSearchString(searchString: string): void {
		// const sanitisedString = String(searchString ?? '');
		// this.searchTerm.emit(sanitisedString);
		// if (this.globalSearch) {
		//   this.filterStore.updateSearchBar(sanitisedString);
		// }
	}

	onInputChange(event: Event): void {
		const inputValue = (event.target as HTMLInputElement).value;
		const sanitisedString = String(inputValue ?? '');
		this.debounceSearch$.next(sanitisedString);
	}
} // end class
