import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnInit, inject, input } from '@angular/core';

import { FormBuilder, ReactiveFormsModule } from '@angular/forms';

import { MatSelect } from '@angular/material/select';

import { KeyValue } from '@ft/lib/models';

import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';

@Component({
	selector: 'ft-filter-date',
	templateUrl: './filter-date.component.html',
	styleUrls: ['./filter-date.component.scss'],
	imports: [MatCheckboxModule, ReactiveFormsModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterDateComponent implements OnInit {
	destroyRef = inject(DestroyRef);
	fb = inject(FormBuilder);

	@Input() label: string = '';
	readonly selectedDateRanges = input<KeyValue[]>();

	dateRanges: KeyValue[] = [];

	constructor() {}

	ngOnInit() {
		// nothing to see here
	}

	clearSelection(event: MouseEvent, ref: MatSelect): void {}

	toggleCheckbox(item: KeyValue, event: MouseEvent) {}

	onChange(item: KeyValue, event: MatCheckboxChange) {}
}
