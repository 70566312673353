<div
  class="user-notification-list-component mat-app-background"
  appEscapeKeyListener
  (escapeKeyDown)="handleEscapeKeyPress()"
>
  <!-- loading bar -->
  <ft-progress-bar [isLoading]="rowsLoading()"></ft-progress-bar>

  <!-- page controls -->
  <div class="page-controls">
    <!-- page buttons -->
    <div class="page-buttons">
      <!-- page totals -->
      <ft-page-totals
        title="Notifications"
        [showing]="visibleRows().length"
        [selectLoaded]="null"
        [selectTotal]="null"
      ></ft-page-totals>

      <!-- <ft-add-button addText="Add new notification" (addClicked)="createNotification()"></ft-add-button> -->
    </div>

    <!-- page search bar -->
    <div class="page-filter"></div>
    <ft-close-button (closeClicked)="close()"></ft-close-button>
  </div>

  <!-- table content -->
  <section class="notification-list mat-elevation-z8" tabindex="0">
    @for (userNote of visibleRows(); track userNote._id; let oddRow = $odd) {
      <mat-card appearance="outlined" class="no-border">
        <mat-card-header class="flex-row space-between width-100">
          <mat-card-title class="notification-name">{{ userNote.notificationName }} </mat-card-title>
          <mat-card-subtitle>{{ userNote.schedule }} </mat-card-subtitle>
          @if (canManageNotifications()) {
            <div class="button-container">
              <button mat-mini-fab color="primary" (click)="editItem(userNote)" aria-label="Edit">
                <fa-icon [icon]="faPentoSquare"></fa-icon>
              </button>
              <button mat-mini-fab color="primary" (click)="deleteItem(userNote._id)" aria-label="Delete">
                <fa-icon [icon]="faTrash"></fa-icon>
              </button>
            </div>
          }
        </mat-card-header>
        <mat-card-content>
          <div class="notification-date">Last updated: {{ userNote.updatedAt | dateAgo }}</div>

          @if (userNote.notificationFilters.searchString) {
            <p><strong>Search String:</strong> {{ userNote.notificationFilters.searchString }}</p>
          }
          <mat-chip-set aria-label="filter-value-set">
            <mat-chip>{{ userNote.notificationFilters.fieldFilters.length }} field filters</mat-chip>
          </mat-chip-set>
        </mat-card-content>
      </mat-card>
    }
  </section>

  <!-- Footer -->
  <div class="footer-buttons">
    <button
      mat-raised-button
      color="primary"
      (click)="createNotification()"
      aria-label="Add New Notification"
      [disabled]="!canManageNotifications()"
    >
      @if (canManageNotifications()) {
        Add New Notification
      } @else {
        View competitions to manage notifications
      }
    </button>
  </div>
</div>
