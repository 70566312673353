import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	ElementRef,
	Inject,
	type OnInit,
	Type,
	inject,
	signal,
	viewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { CdkDrag } from '@angular/cdk/drag-drop';

import { MatButtonModule } from '@angular/material/button';
import { faSliders } from '@fortawesome/free-solid-svg-icons';

import { NgClass, NgComponentOutlet } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import {
	ActiveFilterService,
	FT_FilterComponent,
	FT_FilterComponentInputs,
	getMatchingSearchBarFields,
} from '@ft/lib/active-filter-lib';
import { AuthStore } from '@ft/lib/auth-lib';
import { SearchBarComponent } from '@ft/lib/filter-searchbar';
import { LayoutInfoService } from '@ft/lib/screen-lib';
import { CloseIconButtonComponent } from '@furnas-technology/angular-library/components';
import {
	FilterValue,
	NotificationFilterField,
	NotificationFilters,
	SearchBarField,
	SearchStringToFieldFilters,
} from '@furnas-technology/common-library/filters';
import { UserNotification, UserNotificationSchedule } from '../../data/user-notification.model';
import { UserNotificationService } from '../../data/user-notification.service';

export interface NotificationDialogData<CompType, T> {
	title: string;
	message: string;
	filterComponent: FT_FilterComponent;
	filterComponentInputs: FT_FilterComponentInputs<CompType>;
	searchBarFields: SearchBarField<T>[];
	userNotification?: UserNotification;
}

@Component({
	selector: 'ft-user-notification-dialog',
	templateUrl: './user-notification-dialog.component.html',
	styleUrls: ['./user-notification-dialog.component.scss'],
	imports: [
		CdkDrag,
		CloseIconButtonComponent,
		FontAwesomeModule,
		FormsModule,
		MatButtonModule,
		MatDialogModule,
		MatFormFieldModule,
		MatInputModule,
		MatRadioModule,
		NgClass,
		NgComponentOutlet,
		ReactiveFormsModule,
		SearchBarComponent,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserNotificationDialog<T> implements OnInit, AfterViewInit {
	protected destroyRef = inject(DestroyRef);
	protected layout = inject(LayoutInfoService);

	authStore = inject(AuthStore);
	dialogRef = inject(MatDialogRef<UserNotificationDialog<T>>);
	private afs = inject(ActiveFilterService);
	userNotificationService = inject(UserNotificationService);

	dialogContent = viewChild<ElementRef>('dialogContent');
	filterComponent = signal<Type<unknown> | null>(null);

	isLoading = false;
	form = new FormGroup({});

	submitTitle = signal<string>('Save');
	title: string = '';
	createNotificationText = '🔔 Create Notification';

	faSliders = faSliders;

	notificationForm = new FormGroup({
		notificationName: new FormControl('', Validators.required),
		schedule: new FormControl('', Validators.required),
	});

	scheduleOptions: string[] = ['Daily', 'Weekly', 'None'];

	constructor(@Inject(MAT_DIALOG_DATA) public dialogData: NotificationDialogData<unknown, T>) {
		console.debug(`${this.constructor.name} - constructor`);

		console.debug(
			`${this.constructor.name} - userNotification=${!!this.dialogData.userNotification}, notificatioName=`,
			this.dialogData.userNotification?.notificationName ?? '',
		);
	}

	ngOnInit() {
		this.title = this.dialogData.title;

		if (this.dialogData.filterComponent) {
			this.filterComponent.set(this.dialogData.filterComponent as Type<unknown>);
		}

		if (this.dialogData.userNotification) {
			const userNotification = this.dialogData.userNotification;
			this.notificationForm.setValue({
				notificationName: userNotification.notificationName,
				schedule: userNotification.schedule,
			});
		}

		if (this.dialogData?.userNotification?._id) {
			this.submitTitle.set('Save');
		} else {
			this.submitTitle.set('Create');
		}
	}

	ngAfterViewInit(): void {
		console.debug(`${this.constructor.name} - ngAfterViewInit - notificationForm=`, this.notificationForm.value);
	}

	onSubmit() {
		this.dialogRef.close();
	}

	close() {
		this.dialogRef.close();
	}

	saveNotification() {
		console.debug(
			`${this.constructor.name} - save - notificationName=${this.notificationForm.value.notificationName}, schedule=${this.notificationForm.value.schedule}`,
		);

		const fieldFilters: NotificationFilterField[] = [];
		for (const ff of this.afs.selectActiveFieldFilters() ?? []) {
			fieldFilters.push({
				fieldnames: ff.fieldnames,
				operation: ff.operation,
				enteredFilterValues: ff.filterValues ? ff.filterValues.map((x) => String(x)) : [],
				filterValues: (ff.filterValues ?? []) as FilterValue[],
				filterDataType: ff.filterDataType,
			});
		}

		const searchBarFields = this.afs.selectActiveSearchBarFields() ?? ([] as SearchBarField<T>[]);
		const searchString = this.afs.selectActiveSearchString() ?? '';
		const searchFieldFilters: NotificationFilterField[] = [];

		if (searchString && searchBarFields.length > 0) {
			const searchBarFilters = SearchStringToFieldFilters<T>(searchString);
			console.debug(`${this.constructor.name} - save - searchBarFilters=`, searchBarFilters);
			for (const sf of searchBarFilters) {
				if (sf.fieldnames.includes('searchbar' as keyof T)) {
					const matchingFields = getMatchingSearchBarFields(searchBarFields, sf.filterDataType);
					sf.fieldnames = matchingFields;
					searchFieldFilters.push({
						fieldnames: sf.fieldnames as string[],
						operation: sf.operation,
						filterDataType: sf.filterDataType,
						filterValues: sf.filterValues,
						enteredFilterValues: sf.enteredFilterValues,
					});
				}
			}
		}

		// Initialise using current active filters
		const notificationFilters: NotificationFilters = {
			searchString: this.afs.selectActiveSearchString() ?? '',
			fieldFilters: fieldFilters,
			searchFieldFilters: searchFieldFilters,
		};

		if (this.dialogData.userNotification?._id) {
			const updatedRecord: UserNotification = {
				...this.dialogData.userNotification,
				notificationName: this.notificationForm.value.notificationName ?? '',
				schedule: this.notificationForm.value.schedule as UserNotificationSchedule,
				notificationFilters: notificationFilters,
			};
			this.userNotificationService.store.mutateRow(updatedRecord);
		} else {
			this.userNotificationService.store.createRow({
				username: this.authStore.username(),
				notificationName: this.notificationForm.value.notificationName ?? '',
				schedule: this.notificationForm.value.schedule as UserNotificationSchedule,
				notificationFilters: notificationFilters,
			});
		}

		this.dialogRef.close({ button: 'save' });
	}
}
