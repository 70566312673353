import {
	type AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	ElementRef,
	type OnChanges,
	type OnDestroy,
	type OnInit,
	type SimpleChanges,
	computed,
	inject,
	input,
	output,
	signal,
	viewChild,
} from '@angular/core';

import { NgClass } from '@angular/common';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faHashnode } from '@fortawesome/free-brands-svg-icons';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { QuillModule } from 'ngx-quill';

import { LayoutInfoService } from '@ft/lib/screen-lib';
import { LoadingBackgroundDirective } from '@furnas-technology/angular-library/directives';
import { DateAgoPipe } from '@furnas-technology/angular-library/pipes';

import {
	CarouselImage,
	DisplayEmbeddedVideoComponent,
	FT_PdfViewerComponent,
	ImageCarouselComponent,
} from '@ft/lib/media-lib';
import { Artefact } from '../../data/artefact.model';
import { ArtefactStore } from '../../store/artefact.store';

@Component({
	selector: 'ft-artefact-article-view',
	templateUrl: './artefact-article-view.component.html',
	styleUrls: ['./artefact-article-view.component.scss'],
	imports: [
		DateAgoPipe,
		DisplayEmbeddedVideoComponent,
		FontAwesomeModule,
		FT_PdfViewerComponent,
		ImageCarouselComponent,
		LoadingBackgroundDirective,
		MatButtonModule,
		MatCardModule,
		NgClass,
		QuillModule,
	],
	providers: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArtefactArticleViewComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
	protected destroyRef = inject(DestroyRef);
	protected layout = inject(LayoutInfoService);
	artefactStore = inject(ArtefactStore);

	artefact = input<Artefact | null | undefined>(null);
	editArtefactId = output<string>();

	artefactCard = viewChild('artefactcard', { read: ElementRef });

	// @ViewChild('artefactcard') artefactCard!: ElementRef;
	// @ViewChild('dialogcontent') dialogcontent!: ElementRef;

	cardHeight = signal(0);
	cardWidth = signal(0);
	faCircleInfo = faCircleInfo;
	faHashnode = faHashnode;

	showDetails = signal(false);

	excerptContent = computed<string>(() => {
		if (this.artefact()?.artefactType === 'Video') {
			console.debug(`${this.constructor.name} - excerptContent - video artefact=${this.artefact()?.artefactName}`);
		}
		return this.artefact()?.lede || this.artefact()?.richText || '';
	});

	detailsContent = computed<string>(() => {
		if (this.artefact()?.artefactType === 'Video') {
			console.debug(`${this.constructor.name} - detailsContent - video artefact=${this.artefact()?.artefactName}`);
		}
		return this.artefact()?.richText || this.artefact()?.lede || '';
	});

	artefactContent = computed<string>(() => {
		if (this.artefact()?.artefactType === 'Video') {
			console.debug(`${this.constructor.name} - artefactContent - video artefact=${this.artefact()?.artefactName}`);
		}

		return this.detailsContent();
	});

	carouselImages = computed<CarouselImage[]>(() => {
		if (this.artefact()?.artefactImages?.length) {
			const artefactImages = this.artefact()?.artefactImages ?? [];
			const images: CarouselImage[] = artefactImages.map((ai, index) => {
				return { url: ai, title: `Image ${index + 1}` };
			});
			return images;
		} else {
			return [];
		}
	});

	constructor() {}

	async ngOnInit() {}

	ngOnChanges(changes: SimpleChanges): void {}

	ngAfterViewInit() {}

	ngOnDestroy() {
		// nothing to see here
	}

	async toggleDetails(event: Event) {
		event.stopPropagation();

		this.showDetails.set(!this.showDetails());
	}

	async onEdit(event: Event, selectedId: string) {
		// stop propagation
		event.stopPropagation();
		if (selectedId) {
			this.editArtefactId.emit(selectedId);
		}
	}

	calculateItemSize() {
		const el = this.artefactCard()?.nativeElement;
		if (!el) return;
		const width = el.clientWidth;
		const height = el.clientHeight;

		if (this.artefact()) {
			this.cardHeight.set(height);
			this.cardWidth.set(width);
		}
	}
}
