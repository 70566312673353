import {
	type AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	LOCALE_ID,
	type OnInit,
	computed,
	inject,
	signal,
} from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import {} from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCirclePlus, faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';

import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormlySelectModule } from '@ngx-formly/core/select';

import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { PageTotalsComponent } from '@ft/lib/components';
import { PromptService } from '@ft/lib/dialogs-lib';
import { LayoutInfoService } from '@ft/lib/screen-lib';
import { CloseIconButtonComponent, ProgressBarComponent } from '@furnas-technology/angular-library/components';
import { DateAgoPipe } from '@furnas-technology/angular-library/pipes';

import { ActiveFilterService } from '@ft/lib/active-filter-lib';
import { AuthStore } from '@ft/lib/auth-lib';
import { TableButtonPressed } from '@ft/lib/tables-lib';
import { EscapeKeyListenerDirective } from '@furnas-technology/angular-library/directives';
import { FieldFilter } from '@furnas-technology/common-library/filters';
import { UserNotification } from '../../data/user-notification.model';
import { UserNotificationService } from '../../data/user-notification.service';

@Component({
	selector: 'ft-user-notification-list',
	templateUrl: './user-notification-list.component.html',
	styleUrls: ['./user-notification-list.component.scss'],
	providers: [{ provide: LOCALE_ID, useValue: navigator.language || 'en-AU' }],
	imports: [
		CloseIconButtonComponent,
		DateAgoPipe,
		EscapeKeyListenerDirective,
		FontAwesomeModule,
		FormlySelectModule,
		MatButtonModule,
		MatCardModule,
		MatChipsModule,
		MatDatepickerModule,
		MatDialogModule,
		MatFormFieldModule,
		MatNativeDateModule,
		MatSelectModule,
		PageTotalsComponent,
		ProgressBarComponent,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserNotificationListComponent implements OnInit, AfterViewInit {
	protected destroyRef = inject(DestroyRef);
	protected layout = inject(LayoutInfoService);
	private dialogRef = inject(MatDialogRef<UserNotificationListComponent>);
	protected authStore = inject(AuthStore);
	private prompt = inject(PromptService);
	private userNotificationService = inject(UserNotificationService);
	private afs = inject(ActiveFilterService);

	faCirclePlus = faCirclePlus;
	faPentoSquare = faPenToSquare;
	faTrash = faTrash;

	userNotifications$ = signal<UserNotification[]>([]);

	displayedColumns: string[] = [];
	stickyColumns: string[] = [];

	rowsShowing = signal(0);
	rowsLoaded = computed(() => this.userNotificationService.documentsLoaded());
	rowsLoading = computed(() => this.userNotificationService.isLoading());
	visibleRows = computed(() => this.userNotificationService.documents());

	canManageNotifications = computed(() => !!this.afs.selectActiveComponentFilter()?.component);

	constructor() {
		console.debug(`${this.constructor.name} - constructor`);
	}

	ngOnInit(): void {}

	ngAfterViewInit(): void {}

	handleEscapeKeyPress(): void {}

	/** Whether the button toggle group contains the id as an active value. */
	isSticky(id: string) {
		return this.stickyColumns.indexOf(id) !== -1;
	}

	close() {
		this.dialogRef.close();
	}

	/**
	 * Create a new user
	 */
	createUser() {
		this.userNotificationService.editDocument({ id: '', modeType: 'Add New' });
	} // end creatUser

	createNotification() {
		console.debug(`${this.constructor.name} - createNotification`);
		const filterDialog = this.afs.selectActiveComponentFilter()?.component;
		if (filterDialog) {
			this.userNotificationService.openNotificationDialog(filterDialog);
		}
	}

	addItem() {
		// const dialogRef = this.dialog.open(ItemEditComponent, {
		//   width: '400px',
		//   data: { mode: 'add' },
		// });
		// dialogRef.afterClosed().subscribe((result) => {
		//   if (result) {
		//     this.items.push({ ...result, id: this.items.length + 1 });
		//   }
		// });
	}

	editItem(item: UserNotification) {
		console.debug(`${this.constructor.name} - editItem - item=`, item);
		const searchString = item.notificationFilters.searchString;
		this.afs.setActiveSearchString(searchString);

		const fieldFilters = item.notificationFilters.fieldFilters.map(
			(x) => new FieldFilter({ fieldnames: x.fieldnames, operation: x.operation, filterValues: x.enteredFilterValues }),
		);
		this.afs.updateActiveFieldFilters(fieldFilters);

		const filterDialog = this.afs.selectActiveComponentFilter()?.component;
		if (filterDialog) {
			this.userNotificationService.openNotificationDialog(filterDialog, item);
		}
	}

	deactivateItem(item: UserNotification) {
		// Implement deactivation logic
		console.log('Deactivate', item);
	}

	deleteItem(id: string) {
		if (!id) return;
		this.userNotificationService.deleteDocument({ id: id });
	}

	/**
	 * Process button pressed on table
	 * @param buttonPressed
	 * @returns
	 */
	handleTableButtonPressed(buttonPressed: TableButtonPressed<UserNotification>) {
		console.debug(`${this.constructor.name} - handleTableButtonPressed - buttonPressed=`, buttonPressed);

		if (!buttonPressed) return;

		if (buttonPressed.tableAction?.buttonType === 'button') {
			// set current user if exists
			if (buttonPressed._id) {
				if (buttonPressed.tableAction.buttonName?.match(/^Details$/i)) {
					this.userNotificationService.store.setSelectedId(buttonPressed._id);
					this.userNotificationService.viewDocument(buttonPressed._id);
				}
			} // end if _id
		} // end if button
	} // end handleTableButtonPressed
} // end class
