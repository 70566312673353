import {
	type AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	type ElementRef,
	Inject,
	type OnInit,
	computed,
	inject,
	input,
	output,
	viewChild,
} from '@angular/core';

import { DOCUMENT, NgClass, NgStyle } from '@angular/common';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faHashnode } from '@fortawesome/free-brands-svg-icons';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { LayoutInfoService } from '@ft/lib/screen-lib';
import { DateAgoPipe } from '@furnas-technology/angular-library/pipes';

import { AuthStore } from '@ft/lib/auth-lib';
import { AddAlternatingBackgroundDirective } from '@ft/lib/directives';
import { DisplayEmbeddedVideoComponent, FT_PdfViewerComponent } from '@ft/lib/media-lib';
import { LoadingBackgroundDirective } from '@furnas-technology/angular-library/directives';
import { QuillModule } from 'ngx-quill';
import { Artefact } from '../../data/artefact.model';
import { ArtefactService } from '../../data/artefact.service';

@Component({
	selector: 'ft-artefact-panel-card',
	templateUrl: './artefact-panel-card.component.html',
	styleUrls: ['./artefact-panel-card.component.scss'],
	imports: [
		AddAlternatingBackgroundDirective,
		DateAgoPipe,
		DisplayEmbeddedVideoComponent,
		FontAwesomeModule,
		FT_PdfViewerComponent,
		LoadingBackgroundDirective,
		MatButtonModule,
		MatCardModule,
		NgClass,
		NgStyle,
		QuillModule,
	],
	providers: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArtefactPanelCardComponent implements OnInit, AfterViewInit {
	protected layout = inject(LayoutInfoService);
	protected authStore = inject(AuthStore);
	private artefactService = inject(ArtefactService);

	artefact = input.required<Artefact>();
	selected = output<boolean>();
	rowType = input<'odd' | 'even' | 'none'>('none');
	rowNumber = input<number>(0);

	artefactCard = viewChild<ElementRef<HTMLElement>>('artefactcard');
	quillviewer = viewChild('quillviewer');

	cardHeight = 0;
	cardWidth = 0;
	faCircleInfo = faCircleInfo;
	faHashnode = faHashnode;

	pdfWidth = '60px';
	pdfHeight = '80px';

	imgWidth = '80px';
	imgHeight = '80px';

	/**
	 * Lede  values
	 */
	trimmedLede = computed(() => {
		return (this.artefact()?.lede ?? '').trim();
	});

	hasLede = computed(() => {
		return this.trimmedLede().length > 0;
	});

	ledeImages = computed(() => {
		if (this.hasLede()) {
			return this.extractImgSrc(this.trimmedLede());
		} else {
			return [];
		}
	});

	hasLedeImages = computed(() => {
		return this.ledeImages().length > 0;
	});

	/**
	 * Rich text values
	 */
	trimmedRichText = computed(() => {
		return (this.artefact()?.richText ?? '').trim();
	});

	hasRichText = computed(() => {
		return this.trimmedRichText().length > 0;
	});

	richTextImages = computed(() => {
		if (this.hasRichText()) {
			return this.extractImgSrc(this.trimmedRichText());
		} else {
			return [];
		}
	});

	hasRichTextImages = computed(() => {
		return this.richTextImages().length > 0;
	});

	/**
	 * Images, pdfs and videos
	 */
	isVideo = computed(() => {
		return this.artefact().artefactType === 'Video';
	});

	imagesLength = computed(() => {
		return this.artefact()?.artefactImages.length ?? 0;
	});

	pdfsLength = computed(() => {
		return this.artefact()?.artefactPdfs.length ?? 0;
	});

	hasImagesAndPdfs = computed(() => {
		return this.imagesLength() > 0 && this.pdfsLength() > 0;
	});

	imagesToShow = computed(() => {
		/**
		 * if lede images, show only lede images (first three maximum)
		 */
		if (this.hasLedeImages()) return this.ledeImages().slice(0, 3);

		const maxImages = this.pdfsLength() > 0 ? 2 : 2;
		if (this.artefact().artefactImages?.length) {
			return this.artefact().artefactImages.slice(0, maxImages);
		} else if (this.hasRichTextImages()) {
			return this.richTextImages().slice(0, maxImages);
		} else {
			return [];
		}
	});

	pdfsToShow = computed(() => {
		// if lede images, show only lede images
		if (this.hasLedeImages()) return [];

		const maxPdfs = this.imagesToShow().length > 0 ? 1 : 2;
		return this.pdfsLength() > 0 ? this.artefact().artefactPdfs.slice(0, maxPdfs) : [];
	});

	mediaToShowCount = computed(() => {
		return this.imagesToShow().length + this.pdfsToShow().length;
	});

	/**
	 * Panel text
	 */
	panelText = computed(() => {
		const potentialText = this.isVideo() ? '' : this.removeImgSrc(this.trimmedLede() || this.trimmedRichText() || '');
		const renderedText = this.getRenderedText(potentialText);
		const dots = renderedText.length > 300 ? '...' : '';
		return renderedText.substring(0, 300) + dots;
	});

	hasPanelText = computed(() => {
		return this.panelText().length > 0;
	});

	panelTextLength = computed(() => {
		return this.panelText().length;
	});

	panelClasses = computed(() => {
		let additionalClasses = this.artefact().artefactType;
		if (this.artefact().artefactType === 'Welcome') {
			// nothing to see here
		} else if (this.rowType() === 'odd') {
			additionalClasses += ' odd';
		} else if (this.rowType() === 'even') {
			additionalClasses += ' even';
		}
		return `${this.layout.cssClasses()} ${additionalClasses}`.trim();
	});

	cardOpacity = computed(() => {
		return this.layout.uiMode() === 'darkMode' ? 0.9 : 0.75;
	});

	constructor(@Inject(DOCUMENT) private document: Document) {}

	async ngOnInit() {
		console.debug(`${this.constructor.name} - ngOnInit - artefact=${this.artefact().artefactName}`);
	}

	ngAfterViewInit() {
		// nothing to see here
	}

	onSelect(selectedId: string) {
		this.selected.emit(true);
	}

	async onEdit(event: Event, id: string) {
		// stop propagation
		event.stopPropagation();
		this.artefactService.editDocument({ id: id, modeType: 'Edit' });
	}

	calculateItemSize() {
		const el = this.artefactCard()?.nativeElement;
		if (!el) return;
		const width = el.clientWidth;
		const height = el.clientHeight;

		if (this.artefact()) {
			this.cardHeight = height;
			this.cardWidth = width;
		}
	}

	extractImgSrc(htmlString: string): string[] {
		const imgTagRegex = /<img\b[^>]*src="([^"]*)"[^>]*>/gi;
		const matches = htmlString.match(imgTagRegex) || [];

		const srcMatches = matches
			.map((tag) => {
				const srcMatch = tag.match(/src="([^"]*)"/);
				return srcMatch ? srcMatch[1] : '';
			})
			.filter((src) => !!src);

		console.debug(`${this.constructor.name} - extractImgSrc - srcMatches=`, srcMatches);
		return srcMatches;
	}

	removeImgSrc(htmlString: string): string {
		const imgTagRegex = /<img\b[^>]*src="([^"]*)"[^>]*>/gi;
		return htmlString.replace(imgTagRegex, '');
	}

	getRenderedText(htmlString: string): string {
		const tempElement = document.createElement('div');
		tempElement.innerHTML = htmlString;
		return tempElement.textContent || '';
	}
}
