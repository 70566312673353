@if (hasFilterComponent()) {
  <div class="filter-button-component">
    <button
      mat-button
      class="filter-button clickable"
      [ngClass]="{ 'active-filters': hasActiveFilter() }"
      (click)="onFiltersClicked($event)"
      aria-label="filter component-button"
      aria-label="Filters"
    >
      <div class="filter-label clickable">
        <fa-icon [icon]="faSliders"></fa-icon>
        @if (hasActiveFilter()) {
          <div class="text-circle">
            <div class="text-circle-number">
              {{ filterCountText() }}
            </div>
          </div>
        }
        <div class="filter-button__text" [ngClass]="{ 'hide-text': layout.isTiny() }">Filters</div>
      </div>
    </button>
  </div>
} @else {
  <div></div>
}
