<!-- <div class="ft-dialog-container"> -->
<div class="ft-dialog-component user-notification-dialog-component" [ngClass]="layout.cssClasses()">
  <div mat-dialog-title class="filter-header">
    <!-- card header  -->
    <div class="title-container">
      <div
        class="title-text"
        cdkDrag
        [cdkDragDisabled]="layout.isLT_small()"
        cdkDragRootElement=".cdk-overlay-pane"
        cdkDragHandle
      >
        <fa-icon [icon]="faSliders"></fa-icon>
        <h4>{{ title }}</h4>
      </div>

      <div class="title-options">
        <ft-close-button (closeClicked)="close()"></ft-close-button>
      </div>
    </div>
  </div>

  <mat-dialog-content class="hide-scrollbars filter-content" #dialogContent [ngClass]="layout.cssClasses()">
    <form [formGroup]="notificationForm" (ngSubmit)="onSubmit()">
      <div class="notification-heading" [ngClass]="layout.cssClasses()">
        <mat-form-field appearance="fill" class="notification-name">
          <mat-label>Notification Name</mat-label>
          <input matInput formControlName="notificationName" required />
          @if (notificationForm.get('notificationName')?.hasError('required')) {
            <mat-error> Notification Name is required </mat-error>
          }
        </mat-form-field>

        <div class="notification-schedule">
          <label>Schedule:</label>
          <mat-radio-group formControlName="schedule" required>
            @for (option of scheduleOptions; track $index) {
              <mat-radio-button [value]="option">
                {{ option }}
              </mat-radio-button>
            }
          </mat-radio-group>
          @if (notificationForm.get('schedule')?.hasError('required') && notificationForm.get('schedule')?.touched) {
            <mat-error> Schedule is required </mat-error>
          }
        </div>
      </div>

      <ft-search-bar class="search-bar"></ft-search-bar>
      @if (!!filterComponent()) {
        <ng-container *ngComponentOutlet="filterComponent(); inputs: dialogData.filterComponentInputs" />
      }
    </form>
  </mat-dialog-content>

  <!-- card actions  -->
  <mat-dialog-actions class="ft-dialog-actions filter-actions">
    <button
      type="submit"
      mat-button
      class="submit-button"
      (click)="saveNotification()"
      [disabled]="notificationForm.invalid"
      aria-label="Submit"
    >
      {{ submitTitle() }}
    </button>
  </mat-dialog-actions>
</div>
