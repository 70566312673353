<div class="artefact-panel-component hide-scrollbars" [ngClass]="layout.cssClasses()">
  @if (artefacts().length) {
    <div class="panel-list hide-scrollbars">
      <cdk-virtual-scroll-viewport
        customVirtualScrollStrategy
        [itemHeights]="itemSizes()"
        [totalHeight]="totalHeight()"
        (scrolledIndexChange)="onScrolledIndexChange($event)"
        [orientation]="'vertical'"
        autosize
        (scroll)="onScroll($event)"
        #scrollViewport
        class="panel-viewport hide-scrollbars width-100-pct"
      >
        <ft-artefact-panel-card
          *cdkVirtualFor="let artefact of artefacts(); trackBy: trackByFn; odd as oddRow; index as i"
          class="clickable hide-scrollbars"
          [attr.id]="artefact._id"
          [artefact]="artefact"
          [rowType]="oddRow ? 'odd' : 'even'"
          [rowNumber]="i"
          (selected)="onSelected(artefact)"
        >
        </ft-artefact-panel-card>
      </cdk-virtual-scroll-viewport>
    </div>
  } @else if (rowsLoading()) {
    <ft-spinner [statusText]="gss.selectInitStatus()"></ft-spinner>
  } @else if (loaded() && noArtefactMessage()) {
    <h3 class="no-artefact-message">{{ noArtefactMessage() }}</h3>
  }
</div>
