<div class="filter-date-component">
  <section>
    @if (label) {
    <div>{{ label }}</div>
    }
    <!-- <input id="competitionDates" type="checkbox" formControlName="competitionDates" /> -->
    <!-- <mat-checkbox
    type="checkbox"
    *ngFor="let item of dateRanges"
    [checked]=""
    (change)="onChange(item, $event)"
    [id]="item.key"
    >
    {{ item.value }}
  </mat-checkbox> -->
  </section>
</div>
