import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	type OnInit,
	computed,
	inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSliders } from '@fortawesome/free-solid-svg-icons';

import { NgClass } from '@angular/common';
import { ActiveFilterService } from '@ft/lib/active-filter-lib';
import { LayoutInfoService } from '@ft/lib/screen-lib';
import { FilterDialogueService } from '../../services/filter-dialogue.service';

@Component({
	selector: 'ft-filter-button',
	templateUrl: './filter-button.component.html',
	styleUrls: ['./filter-button.component.scss'],
	imports: [NgClass, FontAwesomeModule, MatButtonModule, MatDialogModule, MatTooltipModule, MatIconModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterButtonComponent implements OnInit {
	private destroyRef = inject(DestroyRef);
	protected layout = inject(LayoutInfoService);
	private cdr = inject(ChangeDetectorRef);
	private afs = inject(ActiveFilterService);
	private fds = inject(FilterDialogueService);

	faSliders = faSliders;

	searchbarInNavigation = computed(() => {
		return !!(this.layout.isGT_small() && this.afs.selectShowSearchBar());
	});

	hasSearchString = computed(() => {
		return this.afs.selectActiveSearchString().length > 0;
	});

	hasFilterComponent = computed(() => {
		return !!this.afs.selectActiveComponentFilter()?.component;
	});

	filterCountText = computed<string>(() => {
		let filterCount = this.afs.visibleFilterCount();
		if (this.searchbarInNavigation() || !this.hasSearchString()) {
			return filterCount > 0 ? filterCount.toFixed() : '';
		}

		filterCount += 1;
		return filterCount > 0 ? filterCount.toFixed() : '';
	});

	hasActiveFilter = computed(() => {
		return !!this.filterCountText();
	});

	constructor() {}

	ngOnInit() {}

	onFiltersClicked(event: MouseEvent | PointerEvent): void {
		const activeFilter = this.afs.selectActiveComponentFilter();
		const filterComponent = activeFilter?.component;
		if (filterComponent) {
			const isExtensible = Object.isExtensible(filterComponent);
			if (!isExtensible) {
				console.warn(
					`${this.constructor.name} - filterComponent ${filterComponent?.name} - isExtensible=${Object.isExtensible(
						filterComponent,
					)}`,
				);
			}

			this.fds.openFilterDialog(filterComponent);
			this.cdr.markForCheck();
		}
	}
}
