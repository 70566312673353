import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	type OnInit,
	computed,
	effect,
	inject,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';

import { MatDialogModule } from '@angular/material/dialog';

import {} from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { type FormlyFieldConfig, type FormlyFormOptions, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { GetMutableObject } from '@ft/lib/core-lib';
import { FT_StandardComponent } from '@ft/lib/standard-types-lib';
import {} from '@furnas-technology/angular-library';
import { CloseIconButtonComponent, ProgressBarComponent } from '@furnas-technology/angular-library/components';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { UserNotification } from '../../data/user-notification.model';
import { UserNotificationService } from '../../data/user-notification.service';
import { userCardFields } from './user-notification-card.formlyfields';

@Component({
	selector: 'ft-user-notification-card',
	templateUrl: './user-notification-card.component.html',
	styleUrls: ['./user-notification-card.component.scss'],
	providers: [{ provide: MAT_DATE_LOCALE, useValue: navigator.language ?? 'en-AU' }],
	imports: [
		CloseIconButtonComponent,
		DragDropModule,
		ProgressBarComponent,
		FontAwesomeModule,
		FormlyMatDatepickerModule,
		FormlyMaterialModule,
		FormlyModule,
		FormlySelectModule,
		FormsModule,
		MatDatepickerModule,
		MatNativeDateModule,
		ReactiveFormsModule,
		MatDialogModule,
		MatButtonModule,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserNotificationCardComponent extends FT_StandardComponent<UserNotification> implements OnInit {
	private cdr = inject(ChangeDetectorRef);
	protected userNotificationService = inject(UserNotificationService);

	rowsLoading = computed(() => this.userNotificationService.isLoading());

	faUser = faUser;

	public fields: FormlyFieldConfig[] = userCardFields;
	options: FormlyFormOptions = {};

	get isEmailInvalid() {
		return this.form.get('email')?.invalid;
	}

	// @Inject(MAT_DIALOG_DATA) public userDialog: UserNotificationDialogModel
	constructor() {
		super();

		this.recordName.set('Notification');

		/**
		 * load data on input changes
		 */
		effect(() => {
			console.debug(`${this.constructor.name} - EFFECT - configId=${this.configId()}, modeType=${this.modeType()}`);
			const { obj: mutableObject, reference } = GetMutableObject(
				this.modeType(),
				this.configId(),
				UserNotification,
				this.userNotificationService.store.selectDocumentById,
			);
			this.loadModel(mutableObject, reference);
		});
	}

	loadModel(userNotificationData: UserNotification, reference: string): void {
		const userNotification = userNotificationData
			? new UserNotification(userNotificationData)
			: new UserNotification({});
		this.reference = reference;
		this.model.set(userNotification);
		this.cdr.detectChanges();
	} // end loadModel

	onModelChange(event: unknown): void {}
} // end class
