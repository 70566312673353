<!-- select field -->
<div class="ft-filter-field" [ngClass]="layout.cssClasses()">
  <p>
    {{ pluralText() }}
    @if (!hideTotals()) {
      ({{ totalOptions().length }})
    }
  </p>

  <ng-select
    #selectElem
    class="select-options"
    name="{{ fieldPath() }}"
    [id]="fieldPath()"
    [items]="availableOption$ | async"
    [placeholder]="'Select' + ' ' + pluralText()"
    bindLabel=""
    [addTag]="false"
    [multiple]="true"
    [hideSelected]="true"
    [trackByFn]="trackByFnString"
    [minTermLength]="1"
    [virtualScroll]="true"
    [loading]="loadingFlag()"
    loadingText="Loading values, please wait"
    typeToSearchText="Please enter 1 or more characters to view available values"
    notFoundText="No matching values"
    [typeahead]="fieldInput$"
    [(ngModel)]="selectedValues"
    [ngModelOptions]="{ standalone: true }"
    (change)="onInputChange(fieldPath())"
    (open)="onOpen(fieldPath())"
    (search)="onSearch($event, fieldPath())"
    (clear)="onClear()"
    [attr.data-fieldpath]="fieldPath()"
  >
  </ng-select>
</div>

<!-- Locations -->
