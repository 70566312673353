import { FT_getProperties } from '@furnas-technology/common-library/functions';
import { gql } from 'apollo-angular';
import { Artefact } from './artefact.model';

// api.constants
export const artefactFieldList = [
	'artefactName',
	'desc',
	'richText',
	'lede',
	'artefactType',
	'backgroundColor',

	'artefactImages',
	'artefactPdfs',
	'artefactSpreadsheets',
	'videoHtml',

	'createdAt',
	'updatedAt',
	'deleted',
	'retrievedAt',
];

export const artefactMutateFields: string[] = [
	'artefactName',
	'desc',
	'richText',
	'lede',
	'artefactType',
	'backgroundColor',
	'artefactImages',
	'artefactPdfs',
	'videoHtml',
	'artefactSpreadsheets',
];

export const ApiUrlsArtefact = {
	totals: () => gql`
    query {
      totals: artefactTotals
    }
  `,

	getArtefacts: () => gql`
      query($filter: FilterFindManyArtefactInput, $sort: SortFindManyArtefactInput, $skip: Int, $limit: Int) {
        artefactMany(filter: $filter, sort: $sort, skip: $skip, limit: $limit) {
          _id
          ${artefactFieldList.join(`\r\n`)}
        }
      },
    `,

	artefactUpdateById: (_id: string, record: Artefact) => {
		const mutateRecord = FT_getProperties(artefactMutateFields, record);

		return {
			mutation: gql`
          mutation ($_id: MongoID!, $record: UpdateByIdArtefactInput!) {
            mutateResult: artefactUpdateById(_id: $_id, record: $record) {
              recordId
              record {
                _id
                ${artefactFieldList.join(`\r\n`)}
              }
            }
          }
        `,
			variables: {
				_id: _id,
				record: mutateRecord,
			},
		};
	},

	artefactDeleteById: (_id: string) => {
		return {
			mutation: gql`
        mutation ($input: DocumentIdInputInput!) {
          deleteResult: artefactDeleteById(input: $input)
        }
      `,
			variables: { input: { documentId: `${_id}` } },
		};
	},

	artefactCreateOne: (record: Partial<Artefact>) => {
		const mutateRecord = FT_getProperties(artefactMutateFields, record);

		return {
			mutation: gql`
          mutation ($record: CreateOneArtefactInput!) {
            createResult: artefactCreateOne(record: $record) {
              recordId
              record {
                _id
                ${artefactFieldList.join(`\r\n`)}
              }
            }
          }
        `,
			variables: { record: mutateRecord },
		};
	},

	getArtefactsByType: () => gql`
      query($filter: FilterFindManyArtefactInput, $sort: SortFindManyArtefactInput) {
        artefactMany(filter: $filter, sort: $sort) {
          _id
          ${artefactFieldList.join(`\r\n`)}
        }
      },
    `,
};
