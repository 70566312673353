@if (artefact() && artefact()._id) {
  <title class="article-title">{{ artefact().artefactName }}</title>

  <div
    class="artefact-panel-card hide-scrollbars"
    #artefactcard
    [ngClass]="layout.cssClasses()"
    [attr.aria-label]="artefact()._id || artefact().artefactName"
    [attr.data-rowNumber]="rowNumber()"
    [attr.data-rowType]="rowType()"
  >
    <mat-card
      class="panel-card alternating-background clickable"
      (click)="onSelect(artefact()._id || '')"
      [ngClass]="panelClasses()"
      addAlternatingBackground
      [backgroundOpacity]="cardOpacity()"
      [evenOrOdd]="rowType()"
    >
      @if (layout.isGT_small() && artefact().updatedAt) {
        <h6 class="updated">Updated: {{ artefact().updatedAt | dateAgo }}</h6>
      }

      <!-- card header  -->
      <mat-card-header class="flex-1 card-header" [ngClass]="layout.cssClasses()">
        <mat-card-title class="mat-h1 artefact-title">
          @if (layout.isGT_medium()) {
            <h2>{{ artefact().artefactName }}</h2>
          } @else {
            <h3>{{ artefact().artefactName }}</h3>
          }
        </mat-card-title>
        <mat-card-subtitle [ngClass]="layout.cssClasses()">
          <h4>{{ artefact().desc }}</h4>
          <!-- hasPanelText={{ hasPanelText() }}, len={{ panelTextLength() }}, hasRichTextImages={{ hasRichTextImages() }} -->
        </mat-card-subtitle>
      </mat-card-header>

      <!-- card content  -->
      <mat-card-content class="artefact-content dynamic-text">
        <div class="text-and-images">
          <!-- images and pdfs -->
          @if (mediaToShowCount() > 0) {
            <div
              class="content-images-and-pdfs"
              [ngStyle]="hasPanelText() ? {} : { float: 'none', 'text-align': 'center' }"
            >
              <!-- images -->
              <div class="content-media" #media>
                @for (image of imagesToShow(); track $index) {
                  <img
                    class="panel-card-image"
                    [src]="image"
                    [ngStyle]="{ width: imgWidth, height: imgHeight }"
                    [alt]="artefact().artefactName"
                  />
                }

                @for (pdf of pdfsToShow(); track $index) {
                  <div class="pdf-file">
                    <ft-pdf-viewer
                      class="pdf hide-scrollbars"
                      style="width: auto; overflow-y: hidden"
                      [height]="pdfHeight"
                      [width]="pdfWidth"
                      [zoomScale]="'page-height'"
                      [pdfSrc]="pdf"
                      [showAll]="false"
                      [autoresize]="true"
                      [originalSize]="false"
                      [fitToPage]="true"
                      [renderText]="false"
                    ></ft-pdf-viewer>
                  </div>
                }
              </div>
            </div>
          }
          <!-- END images and pdfs -->

          <!-- video content  -->
          @if (artefact().artefactType === 'Video') {
            @if (artefact().videoHtml) {
              <div class="video-container" loadingBackground>
                <ft-display-embedded-video [embeddedHtml]="artefact().videoHtml"></ft-display-embedded-video>
              </div>
            }
            <!-- text content  -->
          } @else {
            <div class="content-text" #articlecontent [innerText]="panelText()"></div>
          }
        </div>
      </mat-card-content>

      <!-- card footer -->
      @if (artefact().desc && layout.isGT_small()) {
        <mat-card-footer class="artefact-footer">
          <div class="footer-text">
            <!-- <span class="font-xsmall">{{ artefact().artefactName }}</span> -->
          </div>
          @if (authStore.isAdmin() && layout.isGT_small()) {
            <div class="footer-buttons">
              <button mat-button class="dark-text" (click)="onEdit($event, artefact()._id)">Edit</button>
            </div>
          }
        </mat-card-footer>
      }
    </mat-card>
  </div>
} @else {
  <div>-30-</div>
}
