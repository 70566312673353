import { Route } from '@angular/router';
import { faMagento } from '@fortawesome/free-brands-svg-icons';
import { faFax, faHandsHoldingChild, faNewspaper, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { ArtefactFilter, ArtefactManagementComponent } from '@ft/lib/artefacts-lib';
import { adminGuard } from '@ft/lib/auth-lib';
import { FT_CreateRouteData } from '@ft/lib/models-routing';

const stylesRouteData = FT_CreateRouteData({ icon: faMagento, showInNavbar: true });
const soloGroupsRouteData = FT_CreateRouteData({ icon: faPeopleGroup, showInNavbar: true });
const ageRouteData = FT_CreateRouteData({ icon: faHandsHoldingChild, showInNavbar: true });
const contentRouteData = FT_CreateRouteData({
	icon: faNewspaper,
	allowedGroups: ['admins'],
	authenticatedStatus: 'authenticated',
	componentFilter: ArtefactFilter,
	showInNavbar: true,
});
const stylePatternsRouteData = FT_CreateRouteData({ icon: faFax, showInNavbar: true });

export const DATAMANAGEMENT_ROUTES: Route[] = [
	{
		title: 'Styles List',
		path: 'styles-list',
		canActivate: [adminGuard],
		data: stylesRouteData,
		loadComponent: () =>
			import('@danceShared/categories-shared/styles-list/styles-list.component').then((mod) => mod.StylesListComponent),
	},
	{
		title: 'Solo/Groups List',
		path: 'groups-list',
		canActivate: [adminGuard],
		data: soloGroupsRouteData,
		loadComponent: () =>
			import('@danceShared/categories-shared/groups-list/groups-list.component').then((mod) => mod.GroupsListComponent),
	},
	{
		title: 'Age Desc List',
		path: 'ages-list',
		canActivate: [adminGuard],
		data: ageRouteData,
		loadComponent: () =>
			import('@danceShared/categories-shared/ages-list/ages-list.component').then((mod) => mod.AgesListComponent),
	},

	{
		title: 'Content',
		path: 'content',
		canActivate: [adminGuard],
		data: contentRouteData,
		component: ArtefactManagementComponent,
	},

	{
		title: 'Style Patterns',
		path: 'style-patterns',
		canActivate: [adminGuard],
		data: stylePatternsRouteData,
		loadComponent: () =>
			import(
				'projects/dance-shared/style-patterns-shared/components/style-pattern-management/style-pattern-management.component'
			).then((mod) => mod.StylePatternManagementComponent),
	},
];
