import { CreatableRow, MutableRow } from '@ft/lib/core-lib';
import { ModeType } from '@furnas-technology/angular-library';

import { FilteredRecord, SearchBarField } from '@furnas-technology/common-library/filters';
import { RemoveWhiteSpaceAndSpecialChars } from '@furnas-technology/common-library/functions';

export const ArtefactTypeValues = [
	'Place',
	'Event',
	'People',
	'Document',
	'Reunion',
	'Publication',
	'Artifact',
	'Genealogy',
	'Photo',
	'Welcome',
	'Video',
	'Background',
	'',
] as const;

export type ArtefactType = (typeof ArtefactTypeValues)[number];

export const ArtefactRoute = (artefactName: string): string => {
	return artefactName ? RemoveWhiteSpaceAndSpecialChars(artefactName) : '';
};

// -------------------------------
// artefact
// -------------------------------
export type ArtefactMutateRecord = MutableRow<Artefact>;
export type ArtefactCreateRecord = CreatableRow<Artefact>;

export class Artefact {
	_id: string = '';
	artefactName: string = '';
	desc: string = '';
	richText: string = '';
	lede: string = '';
	artefactType: ArtefactType = '';
	backgroundColor: string = '';

	artefactImages: string[] = [];
	artefactPdfs: string[] = [];
	artefactSpreadsheets: string[] = [];
	videoHtml: string = '';

	pinOrder: number = -1;

	deleted: boolean = false;
	createdAt: string = '';
	updatedAt: string = '';
	retrievedAt: string = '';

	// virtual fields
	public readonly EXISTS = true;

	_hasLede: boolean = false;
	_panelContent: string = '';

	constructor(init?: Partial<Artefact>) {
		if (init) {
			Object.assign(this, init);

			if (!this.artefactImages) this.artefactImages = [];
			if (!this.artefactPdfs) this.artefactPdfs = [];
			if (!this.artefactSpreadsheets) this.artefactSpreadsheets = [];

			if (!this.pinOrder || Number.isNaN(Number(this.pinOrder))) this.pinOrder = -1;

			// clear updatedAt for welcome artefacts
			if (this.artefactType === 'Welcome' || this.artefactType === 'Background') {
				this.updatedAt = '';
			}
		}

		// virtuals
		this._hasLede = Boolean(this.lede) && String(this.lede).trim().length > 0;
		this._panelContent = (this._hasLede ? this.lede.trim() : this.richText.trim()) ?? '';
	}
}
export default interface ArtefactDialogModel {
	modeType: ModeType;
	id: string;
}

export type ArtefactGroup = {
	artefactType: string;
	artefactNames: string[];
};

export const ArtefactSearchBarFields: SearchBarField<Artefact>[] = [
	new SearchBarField<Artefact>('artefactName', ['string']),
	new SearchBarField<Artefact>('desc', ['string']),
	new SearchBarField<Artefact>('richText', ['string']),
	new SearchBarField<Artefact>('lede', ['string']),
	new SearchBarField<Artefact>('artefactType', ['string']),
];

export type FilteredArtefact = FilteredRecord<Artefact>;
