import { ComponentFilterDefinition } from '@ft/lib/active-filter-lib';
import { ArtefactFilterFormComponent } from '../components/artefact-filter-form/artefact-filter-form.component';
import { Artefact, ArtefactSearchBarFields } from './artefact.model';

export const ArtefactFilter: ComponentFilterDefinition<Artefact> = {
	filterName: 'artefact',
	component: ArtefactFilterFormComponent,
	initialFilters: [],
	searchBarFields: ArtefactSearchBarFields,
};

export const signalStoreName = 'ArtefactStore';

export const nameProperty: keyof Artefact = 'artefactName';
export const sortProperty: keyof Artefact = 'artefactName';
export const getManyProperty = 'artefactMany';
export const resultProperty = 'artefact';
