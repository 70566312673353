import type { FormlyFieldConfig } from '@ngx-formly/core';

export const userCardFields: FormlyFieldConfig[] = [
	{
		fieldGroupClassName: 'display-flex-row',
		expressions: {
			// nothing to see here
		},
		fieldGroup: [
			{
				className: 'flex-0',
				type: 'image-upload',
				key: 'userLogo',
				props: {
					label: 'Logo',
					placeholder: 'Org\r\nLogo',
					readonly: false,
					height: '56px',
					width: '64px',
				},
				expressions: {
					// 'props.disabled': '!model.user.name',
				},
			},
			{
				className: 'flex-1',
				type: 'input',
				key: 'name',
				props: {
					label: 'User Name',
					placeholder: 'Enter the user name',
					readonly: false,
					required: true,
				},
			},
		],
	},

	{
		fieldGroupClassName: 'display-flex-row',
		expressions: {
			'props.disabled': '!model.name',
			hide: '!model.name',
		},
		fieldGroup: [
			{
				className: 'flex-1',
				type: 'input',
				key: 'desc',
				props: {
					label: 'Description',
					placeholder: 'Enter a description of the user',
					readonly: false,
				},
			},
		],
	},

	{
		fieldGroupClassName: 'display-flex-row',
		expressions: {
			'props.disabled': '!model.name',
			hide: '!model.name',
		},
		fieldGroup: [
			{
				className: 'flex-1',
				type: 'input',
				key: 'address',
				props: {
					label: 'Address',
					placeholder: 'Enter the main address',
					readonly: false,
				},
			},
		],
	},

	{
		fieldGroupClassName: 'display-flex-row',
		expressions: {
			'props.disabled': '!model.name',
			hide: '!model.name',
		},
		fieldGroup: [
			{
				className: 'flex-1',
				type: 'input',
				key: 'email',
				props: {
					label: 'Email',
					placeholder: 'Enter the primary email',
					readonly: false,
				},
			},
			{
				className: 'flex-1',
				type: 'input',
				key: 'website',
				props: {
					label: 'Website',
					placeholder: 'Enter the user website',
					readonly: false,
				},
			},
		],
	},

	{
		key: 'deleted',
		type: 'radio',
		props: {
			label: 'Status',
			placeholder: 'Active user?',
			options: [
				{ value: false, label: 'Active' },
				{ value: true, label: 'Not Active' },
			],
		},
		validation: {
			show: true,
		},
		expressions: {
			'props.disabled': '!model.name',
			hide: '!model.name',
		},
	},
];
