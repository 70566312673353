import { Injectable, computed, inject } from '@angular/core';

import { type Observable, map } from 'rxjs';

import { ActiveFilterService } from '@ft/lib/active-filter-lib';
import { DeleteDocumentRequest, EditDocumentRequest } from '@ft/lib/crud-helper-lib';
import { FT_DocumentService } from '@ft/lib/document-lib';
import { GroupByProperty } from '@ft/lib/facade-lib';
import { GraphqlDataService, ReadInput } from '@ft/lib/graphql-lib';
import { GenericStoreServiceInput } from '@ft/lib/store-service-lib';
import { SearchFilter } from '@furnas-technology/angular-library';
import { ArtefactArticleViewComponent } from '../components/artefact-article-view/artefact-article-view.component';
import { ArtefactEditCardComponent } from '../components/artefact-edit-card/artefact-edit-card.component';
import { ArtefactStore } from '../store/artefact.store';
import { ApiUrlsArtefact } from './apiurls.artefact';
import { nameProperty, resultProperty, signalStoreName } from './artefact.constants';
import { Artefact, ArtefactRoute, ArtefactType } from './artefact.model';

const genericStoreServiceInput: GenericStoreServiceInput<Artefact> = {
	signalStoreName: signalStoreName,
	signalStore: ArtefactStore,

	viewComponent: ArtefactArticleViewComponent,
	editComponent: ArtefactEditCardComponent,
	resultProperty: resultProperty,
	nameProperty: nameProperty,
};

// extends GenericStoreService<Artefact>
@Injectable({
	providedIn: 'root',
})
export class ArtefactService {
	protected afs = inject(ActiveFilterService);
	readonly store = inject(ArtefactStore);
	private graphql = inject(GraphqlDataService);
	private documentService = inject(FT_DocumentService);

	/**
	 * Surface store values
	 */
	isLoading = this.store.isLoading;
	isLoaded = this.store.isLoaded;

	lastEntityUpdated = this.store.lastEntityUpdated;

	totals = this.store.totals;
	documentsLoaded = this.store.documentsLoaded;
	documents = this.store.documents;
	filteredDocumentsNotOmitted = this.store.filteredDocumentsNotOmitted;
	filteredDocumentsBySearchBarOnly = this.store.filteredDocumentsBySearchBarOnly;

	viewComponent = ArtefactArticleViewComponent;
	editComponent = ArtefactEditCardComponent;

	constructor() {
		// super(genericStoreServiceInput);
		console.debug(`${this.constructor.name} - constructor - ${genericStoreServiceInput.signalStoreName}`);
	}

	/**
	 *  virtual fields
	 */
	searchArtefacts = computed(() => this.store.filteredDocuments().map((x) => x.record));

	artefactNames = computed(() => {
		const rows = this.afs.excludeFieldsFilter(this.searchArtefacts(), ['artefactName'], 'notOmitted');
		const values = GroupByProperty(rows, 'artefactName');
		return Array.from(values)
			.filter((x) => !!String(x).trim())
			.sort();
	});

	artefactTypes = computed(() => {
		const rows = this.afs.excludeFieldsFilter(this.searchArtefacts(), ['artefactType'], 'notOmitted');
		const values = GroupByProperty(rows, 'artefactType');
		return Array.from(values)
			.filter((x) => !!String(x).trim())
			.sort();
	});

	getArtefactsByType(artefactType: ArtefactType): Observable<Artefact[]> {
		const searchFilter: SearchFilter = { artefactType: artefactType };

		const getManyRequest: ReadInput<Artefact> = {
			objectClass: Artefact,
			resultProperty: 'artefactMany',
			apiRead: ApiUrlsArtefact.getArtefactsByType(),
		};

		const resultData = this.graphql.getDataFunction(ApiUrlsArtefact.getArtefactsByType(), searchFilter).pipe(
			map((response) => {
				const artefacts: Artefact[] = response?.data?.artefactMany ?? [];
				return artefacts;
			}),
		);
		return resultData;
	}

	getArtefactByRouteName(name: string): Artefact | undefined {
		const findName = ArtefactRoute(name);
		return this.store.documents().find((item) => ArtefactRoute(item.artefactName) === findName);
	}

	/**
	 * CRUDs
	 */

	addDocument(request: EditDocumentRequest): void {
		const requestComponent = this.editComponent;

		// const createRowFunction = (rowData: Partial<T>) => this.signalStore.createRow(rowData);
		const createRowFunction = this.store.createRow;
		this.documentService.addDocument<Artefact>(requestComponent, request, createRowFunction);
	}

	editDocument(request: EditDocumentRequest): void {
		const requestComponent = this.editComponent;

		const createRowFunction = this.store.createRow;
		const mutateRowFunction = this.store.mutateRow;
		this.documentService.editDocument<Artefact>(requestComponent, request, createRowFunction, mutateRowFunction);
	}

	deleteDocument(request: DeleteDocumentRequest): void {
		const nameProperty = 'artefactName';
		this.documentService.deleteDocument<Artefact>(request, nameProperty, this.store.entities(), this.store.deleteRow);
	}
} // end class
